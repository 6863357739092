import React from "react";
import styled, { keyframes } from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";

const { colors } = ChargeMediaTheme;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  width: 100%; /* To center horizontally */
  height: 100%; /* To center vertically */
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${colors.red}; /* Use the red color from your theme */
  border-radius: 50%;
  animation: ${spinAnimation} 1s ease-in-out infinite;
`;

const LoadingIndicator = () => {
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
};

export default LoadingIndicator;
