import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`;

const Button = styled(Link)`
  background-color: ${(props) => props.theme.colors.darkGrey};
  width: 125px;
  height: 75px;
  border-radius: ${(props) => props.theme.dimens.boxRadius}
    ${(props) => props.theme.dimens.boxRadius} 0 0;
  border: none;
  color: ${(props) => props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: background-color ${(props) => props.theme.timings.btnFade}
    ease-out;
  animation: ${slideInAnimation} ${(props) => props.theme.timings.btnFade}
    ease-out;
  animation-fill-mode: both;
  opacity: 0;
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

const BurgerMenuIcon = styled(FaBars)`
  /* Add any additional styles for the icon if needed */
`;
const BurgerMenuButtonComponent = ({ text, to, onClick, delay }) => {
  const [animationDelay, setAnimationDelay] = useState(delay || 0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationDelay(0);
    }, animationDelay);

    return () => clearTimeout(timeout);
  }, [animationDelay]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      onClick={handleClick}
      to={to}
      style={{ animationDelay: `${animationDelay}ms` }}
    >
      <BurgerMenuIcon />
    </Button>
  );
};

export default BurgerMenuButtonComponent;
