import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../ui/BackButton";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_URL, WEBSITE_URL } from "../../config";
import SingleLineH1 from "../ui/SingleLineH1";
import SingleLineH2 from "../ui/SingleLineH2";
import SingleLineH3 from "../ui/SingleLineH3";
import SingleLineText from "../ui/SingleLineText";
import EmailLink from "../ui/EmailLink";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import PdfViewer from "../ui/PdfViewer";
import Websites from "./portfolio/Websites";
import flags from "../props/flags";
const { dimens, colors } = ChargeMediaTheme;
const ImprintContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`;

const ImprintBox = styled.div`
  width: 60%;
  height: 80%;
  background-color: ${colors.lightGrey};
  border-radius: ${dimens.boxRadius};
  color: #292c2c;

  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transform: translateY(${({ isopen }) => (isopen ? "0" : "100%")});
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
`;

const ImprintTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 100px;
  margin-bottom: ${(props) => props.theme.padding.small};
  background-color: ${colors.lightGrey};
  border-radius: ${dimens.boxRadius};
  opacity: ${({ isopen }) => (isopen ? 1 : 0)};
  transform: translateY(${({ isopen }) => (isopen ? "0" : "100%")});
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
`;

const Policy = ({ toggleMainMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isopen, setisopen] = useState(false);
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const pdfUrl = "/ChargemediaDSGVO.pdf";
  useEffect(() => {
    setisopen(true);
  }, []);

  useEffect(() => {
    if (location.state && location.state.fromImprint) {
      setisopen(false);
      setTimeout(() => {
        navigate(location.state.fromImprint, { replace: true });
      }, 500);
    }
  }, [location, navigate]);

  if (error) {
    return (
      <ImprintContainer isopen={isopen}>
        An error occurred: {error.message}
      </ImprintContainer>
    );
  }

  return (
    <>
      <ImprintContainer isopen={isopen}>
        <ImprintTitle isopen={isopen}>
          <SingleLineH1 alignment={"center"} text={t("policy")} />
        </ImprintTitle>
        <ImprintBox isopen={isopen}>
          <PdfViewer pdfUrl={pdfUrl} />
        </ImprintBox>
      </ImprintContainer>
    </>
  );
};

export default Policy;
