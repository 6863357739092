import React from "react";
import styled from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import ArrowIcon from "../shapes/ArrowIcon";

const { colors } = ChargeMediaTheme;

const PaginationButton = styled.div`
  width: 45px;
  height: 40px;
  background-color: ${(props) => props.color || colors.darkGrey};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor}; /* Replace with your desired hover color */
  }
`;

export const ArrowButton = ({
  direction,
  onClick,
  color,
  colorFade,
  hoverColor,
}) => {
  return (
    <PaginationButton
      onClick={onClick}
      color={color}
      hoverColor={hoverColor}
      style={{ transition: `background-color ${colorFade || 0.3}s ease` }}
    >
      <ArrowIcon direction={direction} hoverColor={hoverColor} />
    </PaginationButton>
  );
};

export const ArrowBackGround = ({ children, color, colorFade, hoverColor }) => {
  return (
    <PaginationButton
      color={color}
      hoverColor={hoverColor}
      style={{ transition: `background-color ${colorFade || 0.3}s ease` }}
    >
      {children}
    </PaginationButton>
  );
};
