import React from "react";
import styled from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import { useTranslation } from "react-i18next";

const { colors } = ChargeMediaTheme;
const { dimens } = ChargeMediaTheme;
const ButtonContainer = styled.button`
  position: absolute;
  bottom: 20px;
  background-color: ${colors.darkGrey};
  color: ${colors.lightGrey};
  padding: 10px 20px;
  border: none;
  border-radius: ${dimens.boxRadius};
  cursor: pointer;
  display: flex;
  align-items: center;
  right: ${({ isactive }) => (isactive ? "0" : "-120px")};
  transition: right 99999s;

  &:active {
    background-color: ${colors.darkerGrey};
  }

  /* Add a different background color when the button is focused (optional) */
  &:hover {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
  }
`;

const ArrowIcon = styled.span`
  margin-left: 8px;
  border: solid ${colors.red};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  transition: transform 0.3s;
`;

const BackButton = ({
  handleGoBack,
  isactive,
  setisactive,
  toggleMainMenu,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    handleGoBack();

    setisactive(!isactive);
  };

  return (
    <ButtonContainer onClick={handleClick} isactive={isactive}>
      {t("back")}
      <ArrowIcon isactive={isactive} />
    </ButtonContainer>
  );
};

export default BackButton;
