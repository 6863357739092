import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CenteredBox,
  BoxContainer,
  ContentBox,
  TitleBox,
  CurrentPageBox,
  BoxRow,
  BoxContentDiv,
  Inner,
  ContentLeft,
  ContentRight,
} from "../../styled/PortfolioOverviewStyled";
import "./portfolStyles.css";
import BackButton from "../../ui/BackButton";
import flags from "../../props/flags";
import Gridview from "../../Gridview";
import { API_URL, WEBSITE_URL, STRAPI_URL } from "../../../config";
import { useTranslation } from "react-i18next";

const WebsitesOverview = ({ toggleMainMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fadeOut, setFadeOut] = useState(false);
  const [isBackButtonActive, setIsBackBtnActive] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const isModelLoaded = flags.isModelLoaded;
  //
  const apiUrl = `${API_URL}/api/website-portfolios?populate=*`;
  const [items, setItems] = useState([]);
  //
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    toggleMainMenu(true);
    setIsBackBtnActive(true);
  }, []);

  const handleGoBack = () => {
    setFadeOut(true);
    toggleMainMenu(true);
    setTimeout(() => navigate(-1), 700); // Wait for 1 second (animation duration) before navigating back
  };

  const handleGridItemClick = (id, thumbnailUrl) => {
    // Check if the ID is an object
    if (typeof id === "object") {
      // Access the ID property of the object
      id = id.id;
    }
    // Find the clicked item in the items array
    const clickedItem = items.find((item) => item.id === id);
    if (clickedItem) {
      // Extract the URL and name of the clicked item
      const url = STRAPI_URL + clickedItem.thumbnailUrl;
      const name = clickedItem.name;
      console.log(`Clicked on item ${id}. URL: ${url}`);
      console.log(`Name: ${name}`);
      navigate(`/portfolio/websites/pj?id=${encodeURIComponent(id)}`, {
        state: { id, name, url },
      });
    } else {
      console.error(`Item with ID ${id} not found.`);
    }
  };

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data:", data);
        if (Array.isArray(data.data)) {
          const items = data.data.map((item) => {
            return {
              id: item.id,
              name: item.attributes.Name,
              thumbnailUrl: item.attributes.Thumbnail.data.attributes.url,
            };
          });

          // Sort the items array based on the highest ID
          const sortedItems = items.sort((a, b) => b.id - a.id);

          console.log("Items:", sortedItems);
          setItems(sortedItems);

          const thumbnailURLs = sortedItems.map((item) => item.thumbnailUrl);
          console.log("Thumbnail URLs:", thumbnailURLs);
        } else {
          console.error("Data.data is not an array:", data.data.Thumbnail.data);
        }
      })
      .catch((error) => console.error(error));
  }, []);
  const id = items.map((item) => item.id);
  const titles = items.map((item) => item.name);
  const thumbnailURLs = items.map((item) => item.thumbnailUrl);

  return (
    <div>
      <div
        className={`websites-container ${
          fadeOut ? "box-fade-out" : "box-fade-in"
        }`}
      >
        {isModelLoaded && (
          <CenteredBox>
            <BoxContainer>
              <Inner>
                <BoxRow>
                  <TitleBox>{t("web")}</TitleBox>
                </BoxRow>
                <BoxContentDiv>
                  <ContentBox>
                    {isMobile && (
                      <Gridview
                        items={items}
                        itemsLength={items.length}
                        title={(itemId) => titles[itemId]}
                        pageSize={3}
                        onClick={(itemId) =>
                          handleGridItemClick(
                            itemId,
                            STRAPI_URL + thumbnailURLs[itemId]
                          )
                        }
                        imgUrl={(itemId) => STRAPI_URL + thumbnailURLs[itemId]}
                      />
                    )}
                    {!isMobile && (
                      <Gridview
                        items={items}
                        itemsLength={items.length}
                        title={(itemId) => titles[itemId]}
                        pageSize={9}
                        onClick={(itemId) =>
                          handleGridItemClick(
                            itemId,
                            STRAPI_URL + thumbnailURLs[itemId]
                          )
                        }
                        imgUrl={(itemId) => STRAPI_URL + thumbnailURLs[itemId]}
                      />
                    )}
                  </ContentBox>
                </BoxContentDiv>
              </Inner>
            </BoxContainer>
          </CenteredBox>
        )}
      </div>
      <BackButton
        handleGoBack={handleGoBack}
        isactive={isBackButtonActive}
        setisactive={setIsBackBtnActive}
      />
    </div>
  );
};

export default WebsitesOverview;
