// Path: src\components\styled\PortfolioStyled.jsx

import styled from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
const { colors, padding, timings, dimens } = ChargeMediaTheme;

export const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  transition: ${timings.ResponsiveDesignswitch};
`;
export const BoxContainer = styled.div`
  position: absolute;
  right: calc(${padding.medium});

  flex-direction: column;
  width: 60%;
  height: 80%;

  background-color: transparent;
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: ${timings.ResponsiveDesignswitch};

  @media (max-width: 1500px) {
    width: 95% !important;
    top: 25% !important;
    height: calc(75% - 60px);
    margin-bottom: 60px;
  }
  @media (max-width: 950px) {
    width: 100% !important;
    margin-left: 0% !important;
  }
  @media (max-width: 865px) {
    top: 0% !important;
    right: 0px;
    height: 100%;
    overflow-y: auto;

    /* Hide scrollbar but keep functionality - Firefox */
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    /* Hide scrollbar - WebKit (Safari and Chrome) */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const Inner = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: transparent;
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
export const BoxRow = styled.div`
  position: relative;
  display: flex;

  justify-content: center;
  align-items: center;
  width: 93%;
  gap: ${padding.small};
  @media (max-width: 950px) {
    width: 100% !important;
  }
`;
export const BoxContentDiv = styled.div`
  position: relative;

  justify-content: center;

  align-items: center;
  width: 93%;
  gap: ${padding.small};
  height: 100%;

  @media (max-width: 950px) {
    width: 100% !important;
  }
`;

export const TitleBox = styled.div`
  position: relative;
  width: 65%;
  height: 62px;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  @media (max-width: 865px) {
    height: 0px;
  }
`;

export const CurrentPageBox = styled.div`
  position: relative;
  width: 35%;
  height: 62px;

  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const ContentBox = styled.div`
  position: relative;
  top: ${padding.small};
  gap: ${padding.small};
  width: 100%;
  height: 100%;

  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;

  justify-content: center;
  align-items: center;
  z-index: 1;

  @media (max-width: 865px) {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: left;
  align-items: left;
  z-index: 1;
  @media (max-width: 865px) {
    width: 100%;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: left;
  align-items: left;
  z-index: 1;
  @media (max-width: 865px) {
    width: 100%;
  }
`;

export const InfoTextBox = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;

  z-index: 1;
  transition: ${timings.ResponsiveDesignswitch};
`;

export const PictureAndText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: ${padding.small} ${padding.small} 0 ${padding.small};
  gap: ${padding.small};
  align-items: center;
  background-color: ${colors.lightGrey};
  width: 50%;
  height: 160px;
`;

export const AboutTextBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 0 ${padding.medium} 0 ${padding.small};
  gap: ${padding.small};
  align-items: left;
  background-color: ${colors.lightGrey};
  width: 93%;
  height: 100%;
  overflow-y: auto;

  /* Hide scrollbar but keep functionality - Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  /* Hide scrollbar - WebKit (Safari and Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }
`;
