import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import GlobalLogo from "../GlobalLogo";
import { ChargeMediaTheme } from "../../ChargemediaTheme";
import LongButton from "../LongButton";
import MobileBtnLong from "./MobileBtnLong";
import { useTranslation } from "react-i18next";
import SingleLineText from "../SingleLineText";
import MobileBtn from "./MobileBtn";
const { colors, padding, dimens } = ChargeMediaTheme;

const NavbarWrapper = styled.div`
  background-color: ${colors.darkGrey};
  color: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 60px;
  padding: 0 16px;
  position: relative;
  z-index: 3;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const BurgerMenu = styled.div`
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
  transform: ${({ open }) => (open ? "rotate(90deg)" : "rotate(0)")};
  transition: transform 0.3s ease-in-out;
`;
const MenuContainer = styled.div`
  display: flex;
  flex-direction: row !important;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: ${colors.lightGrey};
  gap: ${padding.small};
  height: 100px;
  justify-content: space-evenly;
  align-items: flex-end; /* Align menu items at the bottom */
  opacity: ${({ open }) => (open ? 1 : 0)};
  max-height: ${({ open }) =>
    open ? "100px" : "0"}; /* Slide animation with max-height */
  overflow: hidden; /* Hide any content that overflows the container */
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add the transition property */
`;

const SubMenuWrapper = styled.div`
  /* Position the SubMenuWrapper below the MenuContainer and center it */
  position: absolute;
  top: 267%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: column !important;
  position: absolute;
  padding-top: ${padding.small};
  left: 0;
  right: 0;
  background-color: ${colors.lightGrey};
  height: auto;
  justify-content: space-evenly;
  align-items: center; /* Align menu items at the bottom */
  opacity: ${({ open }) => (open ? 1 : 0)};
  max-height: ${({ open }) =>
    open ? "auto" : "0"}; /* Slide animation with max-height */
  overflow: hidden; /* Hide any content that overflows the container */
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add the transition property */
`;
const MenuItem = styled.h1`
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
  cursor: pointer;
  position: relative; /* Ensure that the MenuItem is positioned relative */
`;

const SubMenuItem = styled.div`
  color: ${colors.darkGrey};
  font-size: 18px;
  cursor: pointer;
`;

const Navbar = ({ toPort, toOff, toAbout }) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuPortOpen, setSubmenuPort] = useState(false);
  const [submenuOffersOpen, setSubmenuOffers] = useState(false);

  const submenuRefs = [useRef(null), useRef(null), useRef(null)];

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
    setSubmenuPort(false);
    setSubmenuOffers(false);
  };

  const handleMenuItemClick = (menuNumber) => {
    console.log(`Menu ${menuNumber} clicked`);
    setSubmenuPort(menuNumber === 1 ? !submenuPortOpen : false);
    setSubmenuOffers(menuNumber === 2 ? !submenuOffersOpen : false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        (menuOpen || submenuPortOpen || submenuOffersOpen) &&
        submenuRefs.some(
          (ref) => ref.current && ref.current.contains(event.target)
        )
      ) {
        setMenuOpen(false);
        setSubmenuPort(false);
        setSubmenuOffers(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [menuOpen, submenuPortOpen, submenuOffersOpen, submenuRefs]);

  return (
    <NavbarWrapper>
      {/* ... (your JSX) ... */}
      <GlobalLogo visible={true} isMobile={true} />
      <BurgerMenu open={menuOpen} onClick={handleMenuClick}>
        ☰
      </BurgerMenu>

      <MenuContainer open={menuOpen}>
        <MobileBtn
          text={t("portfolio")}
          visible={true}
          onClick={() => handleMenuItemClick(1)}
          to={toPort}
        ></MobileBtn>

        <MobileBtn
          text={t("offers")}
          visible={true}
          onClick={() => handleMenuItemClick(2)}
          to={toOff}
        ></MobileBtn>
        <MobileBtn
          text={t("about")}
          visible={true}
          onClick={() => handleMenuItemClick(3)}
          to={toAbout}
        ></MobileBtn>
      </MenuContainer>
      <SubMenuWrapper>
        <SubMenuContainer open={submenuPortOpen} ref={submenuRefs[0]}>
          {submenuPortOpen && (
            <MobileBtnLong
              text={t("web")}
              visible={true}
              to={toPort + "/websites"}
            />
          )}
          {submenuPortOpen && (
            <MobileBtnLong
              text={t("print")}
              visible={true}
              to={toPort + "/print"}
            />
          )}
          {submenuPortOpen && (
            <MobileBtnLong
              text={t("video")}
              visible={true}
              to={toPort + "/video"}
            />
          )}
          {submenuPortOpen && (
            <MobileBtnLong
              text={t("plugins")}
              visible={true}
              to={toPort + "/plugins"}
            />
          )}
        </SubMenuContainer>
        <SubMenuContainer open={submenuOffersOpen} ref={submenuRefs[1]}>
          {submenuOffersOpen && (
            <MobileBtnLong
              text={t("web")}
              visible={true}
              to={toOff + "/websites"}
            />
          )}
          {submenuOffersOpen && (
            <MobileBtnLong
              text={t("print")}
              visible={true}
              to={toOff + "/print"}
            />
          )}
          {submenuOffersOpen && (
            <MobileBtnLong
              text={t("video")}
              visible={true}
              to={toOff + "/video"}
            />
          )}
        </SubMenuContainer>
      </SubMenuWrapper>
    </NavbarWrapper>
  );
};
export default Navbar;
