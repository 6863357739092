import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import { useSpring, animated } from "react-spring";

const { colors, dimens } = ChargeMediaTheme;

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: ${(props) => props.marginLeft};
  overflow: hidden;
`;

const ImageContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: ${dimens.boxRadius};
`;

const MiniGallerySingle = ({ image, width, height, marginLeft }) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const fadeSpring = useSpring({
    opacity: isOverlayVisible ? 1 : 0, // Show the div when overlay is visible
    transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`, // Translate to mouse position
  });

  return (
    <GalleryContainer>
      <ImageWrapper
        width={width}
        height={height}
        marginLeft={marginLeft}
        onMouseMove={(e) => setMousePosition({ x: e.clientX, y: e.clientY })}
        onMouseEnter={() => {
          setOverlayVisible(true);
          setSelectedImage(image);
        }}
        onMouseLeave={() => setOverlayVisible(false)}
      >
        <ImageContainer
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <animated.div
          style={{
            ...fadeSpring,
            position: "relative",
            width: width, // Adjust width as needed
            height: height, // Adjust height as needed
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: dimens.boxRadius,
            pointerEvents: "none", // Allow interaction with the underlying image
            zIndex: 9999, // Add higher z-index to the fading div
          }}
        />
      </ImageWrapper>
    </GalleryContainer>
  );
};

export default MiniGallerySingle;
