import React from "react";
import styled from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";

const { colors } = ChargeMediaTheme;

const PageText = styled.p`
  color: ${(props) => props.color};
  transition: color 0.3s ease; /* Add transition for smooth color change */
`;

const PaginationButton = styled.div`
  width: 45px;
  height: 40px;
  background-color: ${(props) => props.color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;

  /* Apply hover color to the PageText component */
  &:hover ${PageText} {
    color: ${(props) => props.hoverColor};
  }
`;

const ArrowButtonText = ({ currentIndex, totalIndex, color, textColor }) => {
  return (
    <PaginationButton color={color}>
      <PageText color={textColor}>
        {currentIndex}/{totalIndex}
      </PageText>
    </PaginationButton>
  );
};

export default ArrowButtonText;
