import React, { useState, useEffect } from "react";
import { useTransition, animated, useSpring } from "react-spring";
import styled from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import { ArrowButton } from "./ArrowButton";
import ArrowButtonText from "./ArrowButtonText";
const { colors, padding, dimens } = ChargeMediaTheme;

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FullscreenOverlay = styled.div`
  position: fixed;
  top: 15%;
  left: 15%;
  width: 75%;
  height: 75%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  @media (max-width: 865px) {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${colors.red};
  font-size: 32px;
  cursor: pointer;
  position: fixed;
  top: 65px;
  right: 10px;
  z-index: 101;
`;
const ImageWrapper = styled.div`
  position: relative;
  height: 185px;
  width: 85%;
  overflow: hidden;
  border-radius: ${dimens.boxRadius};
`;

const ImageContainer = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: ${dimens.boxRadius};
`;

const Overlay = styled(animated.div)`
  position: fixed;
  top: ${({ mouseY }) => `${mouseY - 50}px`}; /* Adjust the value as needed */
  left: ${({ mouseX }) => `${mouseX - 50}px`}; /* Adjust the value as needed */
  transform: translate(-94.5%, 9%);
  width: 50%;
  height: 60%;
  background-color: transparent;
  pointer-events: none;
  z-index: 9999;
`;

const EnlargedImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: ${padding.small} 0;
`;

const HoverableArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  /* Fallback for browsers that do not support backdrop-filter */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      255,
      255,
      255,
      0.5
    ); /* Adjust the color and opacity as needed */
    opacity: 0;
    pointer-events: none;
    transition: 5.3s ease-in-out;
  }

  /* Apply blur effect for modern browsers */
  backdrop-filter: blur(1px);
  background-color: rgba(
    41,
    44,
    44,
    0.8
  ); /* Adjust the color and opacity as needed */

  /* Show the overlay on hover */

  ${ImageContainer}:hover &::before {
    opacity: 1;
  }
`;
const MiniGallery = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalImages = images.length;
  const currentImage = currentIndex + 1;
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const fadeSpring = useSpring({
    opacity: isOverlayVisible ? 1 : 0,
  });

  const scaleSpring = useSpring({
    transform: `scale(${isHovered ? 1.2 : 1})`,
  });

  const transitions = useTransition(images[currentIndex], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 375 },
  });
  const toggleFullscreen = (image) => {
    setSelectedImage(image);
    setIsFullscreen(!isFullscreen);
  };
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 865);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    let interval;
    if (!isHovered) {
      interval = setInterval(goToNext, 10000);
    }
    return () => clearInterval(interval);
  }, [currentIndex, isHovered]);

  console.log("GALLERY GETS", images.length, " IMAGES");
  return (
    <GalleryContainer>
      <ImageWrapper onMouseMove={handleMouseMove}>
        {transitions((style, item) => (
          <ImageContainer
            style={{
              ...style,
              backgroundImage: `url(${item})`,
              ...scaleSpring,
            }}
            onClick={() => (isMobile ? toggleFullscreen(item) : null)}
            onMouseEnter={() => {
              setOverlayVisible(isMobile ? false : true);
              setSelectedImage(item);
              setIsHovered(isMobile ? false : true);
            }}
            onMouseLeave={() => {
              setOverlayVisible(false);
              setIsHovered(false);
            }}
          >
            {isOverlayVisible && <HoverableArea />}
          </ImageContainer>
        ))}
      </ImageWrapper>
      {isOverlayVisible && !isFullscreen && (
        <Overlay
          style={fadeSpring}
          mouseX={mousePosition.x}
          mouseY={mousePosition.y}
        >
          <EnlargedImage src={selectedImage} alt="Enlarged" />
        </Overlay>
      )}
      {isFullscreen && (
        <FullscreenOverlay>
          <CloseButton onClick={() => setIsFullscreen(false)}>X</CloseButton>
          <EnlargedImage src={selectedImage} alt="Enlarged" />
        </FullscreenOverlay>
      )}
      {images.length > 1 && (
        <ButtonContainer>
          <ArrowButton
            direction="down"
            onClick={goToPrev}
            color={colors.darkGrey}
            hoverColor={colors.lightGrey}
          />

          <ArrowButtonText
            currentIndex={currentImage}
            totalIndex={totalImages}
            color={colors.darkGrey}
          />
          <ArrowButton
            direction="up"
            onClick={goToNext}
            color={colors.darkGrey}
            hoverColor={colors.lightGrey}
          />
        </ButtonContainer>
      )}
    </GalleryContainer>
  );
};

export default MiniGallery;
