import React, { useRef, useEffect, useState, useMemo, Suspense } from "react";
import { Canvas, extend, useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { PerspectiveCamera, PCFSoftShadowMap } from "three";
import TWEEN from "tween.js";
import LoadingScreen from "./LoadingScreen";
import Imprint from "./pages/Imprint";
import ModelLoader from "./ModelLoader";
import * as THREE from "three";
import { Text, Box } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Plugins from "./pages/portfolio/Plugins";
import WebsitesOverview from "./pages/portfolio/WebsitesOverview";
import Websites from "./pages/portfolio/Websites";
import Print from "./pages/portfolio/Print";
import Video from "./pages/portfolio/Video";
import RoundPlane from "./shapes/RoundPlane";
import BackButton from "./ui/BackButton";
import flags from "./props/flags";
import ModelLoaders from "./props/ModelLoaders";
import VideoOverview from "./pages/portfolio/VideoOverview";
import PrintOverview from "./pages/portfolio/PrintOverview";
import PluginsOverview from "./pages/portfolio/PluginsOverview";
import WebsitesOverviewOffers from "./pages/offers/WebsitesOverviewOffers";
import VideoOverviewOffers from "./pages/offers/VideoOverviewOffers";
import PrintOverviewOffers from "./pages/offers/PrintOverviewOffers";
import WebsitesOffer from "./pages/offers/WebsitesOffer";
import VideoOffer from "./pages/offers/VideoOffer";
import PrintOffer from "./pages/offers/PrintOffer";
import AboutUs from "./pages/AboutUs";
import Policy from "./pages/Policy";
import Cookies from "./Coockies";

extend({ GLTFLoader });

const Room = () => {
  const gltf = useLoader(GLTFLoader, "/models/room.gltf");
  const roomObject = useMemo(() => gltf.scene.clone(), [gltf.scene]);

  return <primitive object={roomObject} />;
};

const InfinitePlane = () => {
  const width = 1000;
  const height = 1000;

  return (
    <RoundPlane
      radius={Math.max(width, height) / 2}
      segments={64}
      receiveShadow={true}
    />
  );
};
const ThreeScene = ({ page, toggleMainMenu, isModelOnClickActive }) => {
  const cameraRef = useRef();
  const cameraAnimTime = 1500;
  const {
    initialCameraPos,
    initialCameraRot,
    initialCameraPosMobile,
    initialCameraRotMobile,
    PortCameraPos,
    PortCameraRot,
    OffCameraPos,
    OffCameraRot,
    AboutCameraPos,
    AboutCameraRot,
    ImprintCameraPos,
    ImprintCameraRot,
    PluginsCameraPos,
    PluginsCameraRot,
    WebsitesCameraPos,
    WebsitesCameraRot,
    PrintCameraPos,
    PrintCameraRot,
    VideoCameraPos,
    VideoCameraRot,
  } = require("./props/cameraData.js");
  const [cameraPosition, setCameraPosition] = useState(initialCameraPos);
  const [cameraRotation, setCameraRotation] = useState(initialCameraRot);
  const [loading, setLoading] = useState(true);
  const [modelLoaded, setModelLoaded] = useState(false);
  const isModelLoaded = flags.isModelLoaded;
  const [hovered, setHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 865);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const skyboxTextureURLs = [
    "/path/to/right_texture.png",
    "/path/to/left_texture.png",
    "/path/to/top_texture.png",
    "/path/to/bottom_texture.png",
    "/path/to/back_texture.png",
    "/path/to/front_texture.png",
  ];
  const camera = useMemo(
    () =>
      new PerspectiveCamera(
        45,
        window.innerWidth / window.innerHeight,
        0.1,
        10000
      ),
    []
  );
  const directionalLightPosition = new THREE.Vector3(0, 1, 1).normalize();

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
  };
  const canvasStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
  };

  const handleModelHover = () => {
    // Add the functionality you want when the model is hovered
    // You can change the appearance of the model here, for example:
    // setModelColor("red");
  };

  const handleModelHoverOut = () => {
    // Add the functionality you want when the hover on the model is over
    console.log("Model hover out!");
    // You can reset the appearance of the model here, for example:
    // setModelColor("blue");
  };

  const handleModelPluginClick = () => {
    console.log("Model clicked!");
    handleMoveToPosition(PluginsCameraPos, PluginsCameraRot);

    setTimeout(() => {
      navigate("/portfolio/plugins");
    }, cameraAnimTime);
  };

  const handleModelWebsiteClick = () => {
    console.log("Model clicked!");
    handleMoveToPosition(WebsitesCameraPos, WebsitesCameraRot);

    setTimeout(() => {
      navigate("/portfolio/websites");
    }, cameraAnimTime);
  };

  const handleModelPrintClick = () => {
    console.log("Model clicked!");
    handleMoveToPosition(PrintCameraPos, PrintCameraRot);

    setTimeout(() => {
      navigate("/portfolio/print");
    }, cameraAnimTime);
  };
  const handleModelAboutClick = () => {
    console.log("Model clicked!");
    handleMoveToPosition(AboutCameraPos, AboutCameraRot);

    setTimeout(() => {
      navigate("/about");
    }, cameraAnimTime);
  };
  const handleModelVideoClick = () => {
    console.log("Model clicked!");
    handleMoveToPosition(VideoCameraPos, VideoCameraRot);

    setTimeout(() => {
      navigate("/portfolio/video");
    }, cameraAnimTime);
  };

  const handleMoveToPosition = (position, rotation) => {
    if (!isModelLoaded) return;

    const start = { ...cameraPosition };
    const end = { ...position };

    const tweenPosition = new TWEEN.Tween(start)
      .to(end, cameraAnimTime)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        setCameraPosition({ ...start });
      })
      .start();

    const tweenRotation = new TWEEN.Tween(cameraRotation)
      .to(rotation, cameraAnimTime)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        setCameraRotation({ ...cameraRotation });
      })
      .start();
  };

  const animate = () => {
    requestAnimationFrame(animate);
    TWEEN.update();
  };
  useEffect(() => {
    const loader = new GLTFLoader();

    loader.load(
      "/models/room.gltf",
      (roomObject) => {
        const model = roomObject.scene;
        model.traverse((o) => {
          if (o.isMesh) {
            o.castShadow = true;
            o.receiveShadow = true;
          }
        });

        setLoading(false);
        toggleMainMenu(true);
        flags.isModelLoaded = true;
        console.log("Model loaded!");
        console.log("roomObject", roomObject);
      },
      undefined,
      (error) => {
        console.error("Error loading scene:", error);
      }
    );
  }, []);

  useEffect(() => {
    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    camera.rotation.set(cameraRotation.x, cameraRotation.y, cameraRotation.z);
  }, [camera, cameraPosition, cameraRotation]);

  useEffect(() => {
    animate();
  }, []);
  const [isBackButtonActive, setIsBackBtnActive] = useState(false);
  const handleGoBack = () => {
    console.log({ toggleMainMenu });
    toggleMainMenu(true);
    setTimeout(() => navigate(-1), 0); // Wait for 1 second (animation duration) before navigating back
  };

  const renderUI = () => {
    switch (page) {
      case "imprint":
        return (
          <div>
            <Imprint toggleMainMenu={toggleMainMenu} />
            <BackButton
              handleGoBack={handleGoBack}
              isactive={true}
              setisactive={setIsBackBtnActive}
            />
          </div>
        );
      case "policy":
        return (
          <div>
            <Policy toggleMainMenu={toggleMainMenu} />
            <BackButton
              handleGoBack={handleGoBack}
              isactive={true}
              setisactive={setIsBackBtnActive}
            />
          </div>
        );
      case "websites":
        return <WebsitesOverview toggleMainMenu={toggleMainMenu} />;
      case "websitesDetail":
        return <Websites toggleMainMenu={toggleMainMenu} />;
      case "video":
        return <VideoOverview toggleMainMenu={toggleMainMenu} />;
      case "videoDetail":
        return <Video toggleMainMenu={toggleMainMenu} />;
      case "print":
        return <PrintOverview toggleMainMenu={toggleMainMenu} />;
      case "printDetail":
        return <Print toggleMainMenu={toggleMainMenu} />;
      case "plugins":
        return <PluginsOverview toggleMainMenu={toggleMainMenu} />;
      case "pluginsDetail":
        return <Plugins toggleMainMenu={toggleMainMenu} />;
      //Offers
      case "websitesOffer":
        return <WebsitesOverviewOffers toggleMainMenu={toggleMainMenu} />;
      case "websitesDetailOffer":
        return <WebsitesOffer toggleMainMenu={toggleMainMenu} />;
      case "videoOffer":
        return <VideoOverviewOffers toggleMainMenu={toggleMainMenu} />;
      case "videoDetailOffer":
        return <VideoOffer toggleMainMenu={toggleMainMenu} />;
      case "printOffer":
        return <PrintOverviewOffers toggleMainMenu={toggleMainMenu} />;
      case "printDetailOffer":
        return <PrintOffer toggleMainMenu={toggleMainMenu} />;
      ///////////////////////
      case "about":
        return <AboutUs toggleMainMenu={toggleMainMenu} />;
      case "portfolio":
        return (
          <BackButton
            handleGoBack={handleGoBack}
            isactive={true}
            setisactive={setIsBackBtnActive}
          />
        );
      default:
        return <Cookies />;
    }
  };

  useEffect(() => {
    // Only handle the camera position if the model is loaded
    if (isModelLoaded) {
      switch (page) {
        case "home":
          if (isMobile) {
            handleMoveToPosition(
              initialCameraPosMobile,
              initialCameraRotMobile
            );
          } else {
            handleMoveToPosition(initialCameraPos, initialCameraRot);
          }
          break;
        case "portfolio":
          handleMoveToPosition(PortCameraPos, PortCameraRot);
          break;
        case "offer":
          handleMoveToPosition(OffCameraPos, OffCameraRot);
          break;
        case "about":
          handleMoveToPosition(AboutCameraPos, AboutCameraRot);
          break;
        case "imprint":
          handleMoveToPosition(ImprintCameraPos, ImprintCameraRot);
          break;
        case "plugins":
          handleMoveToPosition(PluginsCameraPos, PluginsCameraRot);
          break;
        case "pluginsDetail":
          handleMoveToPosition(PluginsCameraPos, PluginsCameraRot);
          break;
        case "websites":
          handleMoveToPosition(WebsitesCameraPos, WebsitesCameraRot);
          break;
        case "websitesDetail":
          handleMoveToPosition(WebsitesCameraPos, WebsitesCameraRot);
          break;
        case "print":
          handleMoveToPosition(PrintCameraPos, PrintCameraRot);
          break;
        case "printDetail":
          handleMoveToPosition(PrintCameraPos, PrintCameraRot);
          break;
        case "video":
          handleMoveToPosition(VideoCameraPos, VideoCameraRot);
          break;
        case "videoDetail":
          handleMoveToPosition(VideoCameraPos, VideoCameraRot);
          break;
        //Offers
        case "websitesOffer":
          handleMoveToPosition(WebsitesCameraPos, WebsitesCameraRot);
          break;
        case "websitesDetailOffer":
          handleMoveToPosition(WebsitesCameraPos, WebsitesCameraRot);
          break;
        case "printOffer":
          handleMoveToPosition(PrintCameraPos, PrintCameraRot);
          break;
        case "printDetailOffer":
          handleMoveToPosition(PrintCameraPos, PrintCameraRot);
          break;
        case "videoOffer":
          handleMoveToPosition(VideoCameraPos, VideoCameraRot);
          break;
        case "videoDetailOffer":
          handleMoveToPosition(VideoCameraPos, VideoCameraRot);
          break;
        ///////////////////////
        default:
          handleMoveToPosition(initialCameraPos, initialCameraRot);
          break;
      }
    }
  }, [page, isModelLoaded]);

  return (
    <div style={containerStyle}>
      {!isModelLoaded && <LoadingScreen />}
      <Canvas
        camera={camera}
        ref={cameraRef}
        style={canvasStyle}
        shadows={true}
        onCreated={({ gl, scene }) => {
          gl.domElement.style.pointerEvents = "auto";
          gl.shadowMap.enabled = true;
          gl.shadowMap.type = PCFSoftShadowMap; // Use PCFSoftShadowMap for better shadow quality
        }}
      >
        {/* Use a directional light (sunlight) for better shadow visibility */}

        <pointLight
          castShadow={true}
          position={[15.3, 5, 13]} // Adjust the position of the point light
          intensity={1}
          color={0x404040}
          shadow-mapSize-width={2048} // Adjust shadow map size
          shadow-mapSize-height={2048} // Adjust shadow map size
        />
        <hemisphereLight
          position={[20, 3, 8]} // Adjust the position of the point light
          intensity={0.7}
          distance={0}
          color={0x404040}
        />
        <Room castShadow={true} receiveShadow={true} />
        <ModelLoaders
          page={page}
          t={t}
          handleModelHover={handleModelHover}
          handleModelHoverOut={handleModelHoverOut}
          handleModelPluginClick={handleModelPluginClick}
          handleModelPrintClick={handleModelPrintClick}
          handleModelVideoClick={handleModelVideoClick}
          handleModelWebsiteClick={handleModelWebsiteClick}
          handleModelAboutClick={handleModelAboutClick}
          isModelOnClickActive={isModelOnClickActive}
        />
        {/*       <InfinitePlane /> */}

        {/*  <Box
          position={[10.9, 1, 8]} // Adjust the position of the cube
          scale={[0.1, 1, 1]} // Adjust the scale of the cube
          castShadow={true} // Enable shadow casting for the cube
        >
          <meshLambertMaterial color="gray" />
        </Box>  */}
      </Canvas>

      {renderUI()}
    </div>
  );
};

export default ThreeScene;
