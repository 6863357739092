// Path: src\components\styled\PortfolioStyled.jsx

import styled, { keyframes } from "styled-components";

import { ChargeMediaTheme } from "../ChargemediaTheme";
const { colors, padding, timings, dimens } = ChargeMediaTheme;

export const CenteredBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const BoxContainer = styled.div`
  position: relative;
  flex-direction: column;
  width: 75%;
  height: 75%;

  background-color: ${colors.darkGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: ${timings.ResponsiveDesignswitch};

  @media (max-width: 1400px) {
    width: 100% !important;
    height: calc(100% - 60px);
    margin-left: 20% !important;
    margin-bottom: 60px;
  }

  @media (max-width: 865px) {
    width: 100% !important;
    margin-left: 0% !important;
  }
`;

export const Inner = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: ${colors.darkGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const BoxRow = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;

  gap: ${padding.small};
  transition: ${timings.ResponsiveDesignswitch};
  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 93%;
    gap: 0;
  }
`;

export const BoxContentDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
  gap: ${padding.small};
  height: 80%;
  transition: ${timings.ResponsiveDesignswitch};

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    height: auto;
    width: 93%;
    margin-top: auto;
    overflow-y: auto; /* Enable scrolling within ContentLeft */

    /* Hide scrollbar but keep functionality - Firefox */
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    /* Hide scrollbar - WebKit (Safari and Chrome) */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TitleBox = styled.div`
  position: relative;
  width: 100%;
  height: 62px;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: left;
  font-weight: bold;
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 1;
  transition: ${timings.ResponsiveDesignswitch};
  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
  }
`;

const slideAnimation = keyframes`
  from {
    transform: translateY(-150%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Titletext = styled.h3`
  color: ${colors.darkGrey};
  margin-left: ${padding.medium};
  margin-right: ${padding.medium};
  animation: ${slideAnimation} 0.75s ease-in-out;
  transition: ${timings.ResponsiveDesignswitch};
  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
  }
`;
export const TitletextPageLink = styled.a`
  color: ${colors.darkGrey};

  margin-left: ${padding.medium};
  animation: ${slideAnimation} 0.75s ease-in-out;
  transition: ${timings.ResponsiveDesignswitch};
  transition: color ${timings.btnFade};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.red};
  }

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 0%;
    height: 62px;
  }
`;

export const TitletextPage = styled.h3`
  color: ${colors.darkGrey};
  margin-left: ${padding.medium};
  animation: ${slideAnimation} 0.75s ease-in-out;
  transition: ${timings.ResponsiveDesignswitch};

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 0%;
    height: 62px;
  }
`;

export const IconBox = styled.div`
  position: absolute;
  width: 10%;
  height: 90%;
  background-color: ${colors.darkGrey};
  left: 89.4%;
  z-index: 1;
  border-radius: ${dimens.boxRadius};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    width: 10%;
    height: 90%;
  }

  @media (max-width: 865px) {
  }
`;

export const CurrentPageBox = styled.div`
  position: relative;
  width: 45%;
  height: 62px;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: ${timings.ResponsiveDesignswitch};

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 0%;
    height: 62px;
  }
`;

export const ContentBox = styled.div`
  position: relative;
  top: ${padding.small};
  gap: ${padding.small};
  width: 100%;
  height: 100%;
  background-color: ${colors.darkGrey};
  color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    flex-direction: column; // Stack content vertically
  }
`;

export const ScrollContainer = styled.div`
  position: relative;
  width: 65%;
  height: 100%;
  overflow: hidden; /* Hide the outer scrollbar */
  transition: ${timings.ResponsiveDesignswitch};
  border-radius: ${dimens.boxRadius};
  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 100%;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: left;
  font-size: 25px;
  font-weight: bold;
  z-index: 1;
  overflow-y: auto; /* Enable scrolling within ContentLeft */

  /* Hide scrollbar but keep functionality - Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  /* Hide scrollbar - WebKit (Safari and Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 865px) {
    height: 100vh;
  }
`;

const DescriptiontextslideAnimation = keyframes`
  from {
    transform: translateY(150%);
  }
  to {
    transform: translateY(0);
  }
`;

export const DescriptionText = styled.p`
  color: ${colors.darkGrey};
  margin-left: ${padding.medium};
  margin-right: ${padding.medium};
  animation: ${DescriptiontextslideAnimation} 0.75s ease-in-out;
`;

export const ContentRight = styled.div`
  position: relative;
  width: 45%;
  height: 100%;
  background-color: ${colors.lightGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: left;
  font-size: 25px;
  font-weight: bold;

  z-index: 1;
  overflow-y: auto; /* Enable scrolling within ContentLeft */

  /* Hide scrollbar but keep functionality - Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  /* Hide scrollbar - WebKit (Safari and Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }
  transition: ${timings.ResponsiveDesignswitch};
  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 100%;
    overflow-y: hidden;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${padding.medium};
  transition: ${timings.ResponsiveDesignswitch};
  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    gap: 0;
    flex-direction: column;
  }
`;

export const BoxContainerMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-color: ${colors.lightGrey};
`;
