import React, { useState, useEffect } from "react";
import ThreeScene from "./components/ThreeScene";
import { ThemeProvider, styled } from "styled-components";
import { ChargeMediaTheme } from "./components/ChargemediaTheme";
import MainMenu from "./components/MainMenu";
import BottomButton from "./components/ui/BottomButton";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import BurgerMenuButton from "./components/ui/BurgerMenuButton";
import XylophoneMenu from "./components/XylophoneMenu";
import GlobalLogo from "./components/ui/GlobalLogo";
import BackButton from "./components/ui/BackButton";
import WebsitesOverview from "./components/pages/portfolio/WebsitesOverview";
import VideoOverview from "./components/pages/portfolio/VideoOverview";
import PluginsOverview from "./components/pages/portfolio/PluginsOverview";
import PrintOverview from "./components/pages/portfolio/PrintOverview";
import Navbar from "./components/ui/mobile/Navbar";
import ImprintButton from "./components/ui/mobile/ImprintButton";
import CookieConsent from "./components/Coockies";
const isMainMenuVis = false;
const isLogoVisible = true;
const isLogoOnTopVis = true;

const App = () => {
  const divStyle = {
    width: "100%",
    height: "100vh",
    backgroundColor: "black",
  };
  const landingPage = "home";
  const menuStyle = {
    position: "absolute",
    zIndex: 1,
  };
  const XyloMenuStyle = {
    position: "absolute",
    zIndex: 1,
  };

  const BurgerMenuContainer = styled.div`
    bottom: 0;
    right: ${(props) => props.theme.padding.medium};
    position: absolute;
    z-index: 1;
  `;

  const ImprintBox = styled.div`
    position: absolute;
    right: calc(${(props) => props.theme.padding.medium} * 4);
    top: 0;
    z-index: 1;
    font-family: "BenchNine", sans-serif;
    font-weight: bold;
  `;
  const PolicyBox = styled.div`
    position: absolute;
    right: calc(${(props) => props.theme.padding.medium} * 9);
    top: 0;
    z-index: 1;
    font-family: "BenchNine", sans-serif;
    font-weight: bold;
  `;
  const ImprintBoxMobile = styled.div`
    position: absolute;
    bottom: 0;
    z-index: 1;
    font-family: "BenchNine", sans-serif;
    font-weight: bold;
  `;
  const BackButtonBox = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    font-family: "BenchNine", sans-serif;
    font-weight: bold;
  `;
  const [inMobile, setinMobile] = useState(false);
  const { t } = useTranslation();

  // const [localIsMainMenuVis, setLocalIsMainMenuVis] = useState(isMainMenuVis);
  const [localIsXylophoneMenuVis, setLocalIsXylophoneMenuVis] =
    useState(isMainMenuVis);
  const [isBackButtonActive, setIsBackBtnActive] = useState(false);

  const [localisLogoVisible, setLogoVis] = useState(isLogoVisible);

  const toggleMainMenu = (XylophoneMenuBool) => {
    setLocalIsXylophoneMenuVis(XylophoneMenuBool);
  };

  const toggleLogo = (logoVis) => {
    setLogoVis(logoVis);
  };

  useEffect(() => {
    const handleResize = () => {
      setinMobile(window.innerWidth < 865);
      if (window.innerWidth < 600) {
        toggleLogo(false);
      } else {
        toggleLogo(true);
      }
    };

    handleResize(); // Call handleResize initially to set the logo visibility on component mount
    window.addEventListener("resize", handleResize); // Add event listener for window resize

    return () => window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
  }, []);

  return (
    <ThemeProvider theme={ChargeMediaTheme}>
      <div style={divStyle}>
        <Router>
          <div style={XyloMenuStyle}>
            {localIsXylophoneMenuVis && !inMobile && (
              <XylophoneMenu
                //onClick={() => toggleMainMenu(false, false)}
                textPort={t("portfolio")}
                toPort={"/portfolio"}
                textOff={t("offers")}
                toOff={"/offer"}
                textAbout={t("about")}
                toAbout={"/about"}
                visible={localIsXylophoneMenuVis}
              />
            )}
          </div>

          <GlobalLogo visible={localisLogoVisible} inMobile={inMobile} />
          {localIsXylophoneMenuVis && inMobile && (
            <Navbar
              toPort={"/portfolio"}
              toOff={"/offer"}
              toAbout={"/about"}
              visible={localIsXylophoneMenuVis}
            />
          )}
          <ImprintBox>
            {localIsXylophoneMenuVis && !inMobile && (
              <BottomButton
                onClick={() => {
                  toggleMainMenu(false);
                }}
                text={t("imprint")}
                to={"/imprint"}
                visible={localIsXylophoneMenuVis}
              />
            )}
          </ImprintBox>
          <PolicyBox>
            {localIsXylophoneMenuVis && !inMobile && (
              <BottomButton
                onClick={() => {
                  toggleMainMenu(false);
                }}
                text={t("policy")}
                to={"/policy"}
                visible={localIsXylophoneMenuVis}
              />
            )}
          </PolicyBox>
          {localIsXylophoneMenuVis && inMobile && (
            <ImprintBoxMobile>
              {" "}
              <ImprintButton
                onClick={() => {
                  toggleMainMenu(false);
                }}
                text={t("imprint")}
                to={"/imprint"}
                visible={localIsXylophoneMenuVis}
                inMobile={inMobile}
              />{" "}
            </ImprintBoxMobile>
          )}

          <Routes>
            {/* Pass toggleMainMenu prop to all routes */}
            <Route
              exact
              path="/"
              element={
                <ThreeScene
                  page={landingPage}
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={true}
                />
              }
            />
            <Route
              exact
              path="/portfolio"
              element={
                <ThreeScene
                  page="portfolio"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={true}
                />
              }
            />
            <Route
              exact
              path="/offer"
              element={
                <ThreeScene
                  page="offer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={true}
                />
              }
            />
            <Route
              exact
              path="/about"
              element={
                <ThreeScene
                  page="about"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/imprint"
              element={
                <ThreeScene
                  page="imprint"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/policy"
              element={
                <ThreeScene
                  page="policy"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/plugins"
              element={
                <ThreeScene
                  page="plugins"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/plugins/pj"
              component={PluginsOverview}
              element={
                <ThreeScene
                  page="pluginsDetail"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/websites"
              element={
                <ThreeScene
                  page="websites"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/websites/pj"
              component={WebsitesOverview}
              element={
                <ThreeScene
                  page="websitesDetail"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/print"
              element={
                <ThreeScene
                  page="print"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/print/pj"
              component={PrintOverview}
              element={
                <ThreeScene
                  page="printDetail"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/video"
              element={
                <ThreeScene
                  page="video"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/portfolio/video/pj"
              component={VideoOverview}
              element={
                <ThreeScene
                  page="videoDetail"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            {/*Offer Routes */}
            <Route
              exact
              path="/offer/websites"
              element={
                <ThreeScene
                  page="websitesOffer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/offer/websites/pj"
              component={WebsitesOverview}
              element={
                <ThreeScene
                  page="websitesDetailOffer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/offer/print"
              element={
                <ThreeScene
                  page="printOffer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/offer/print/pj"
              component={PrintOverview}
              element={
                <ThreeScene
                  page="printDetailOffer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/offer/video"
              element={
                <ThreeScene
                  page="videoOffer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
            <Route
              exact
              path="/offer/video/pj"
              component={VideoOverview}
              element={
                <ThreeScene
                  page="videoDetailOffer"
                  toggleMainMenu={toggleMainMenu}
                  isModelOnClickActive={false}
                />
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
