import React, { useState, useEffect } from "react";
import { API_URL, WEBSITE_URL, STRAPI_URL } from "../../config";
import { keyframes, styled } from "styled-components";
import flags from "../props/flags";
import { ChargeMediaTheme } from "../ChargemediaTheme";

const apiUrl = `${API_URL}/api/einstellungen?populate=*`;

// Updated LogoContainer styles to conditionally set 'bottom' property based on 'isMobile' prop
const LogoContainer = styled.div`
  display: flex;
  position: absolute;
  left: -10px;

  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  ${(props) => (props.isMobile ? `width: 120px;` : `width: 149px;`)}
  ${(props) => (props.isMobile ? `height: 55px;` : `height: 55px;`)}
 
  z-index: 3;
  transition: transform 0.375s ease-out;
  &:hover {
    transform: translateX(10px);
  }

  /* Set the bottom property based on isMobile prop */
  ${(props) =>
    props.isMobile
      ? `top: ${ChargeMediaTheme.padding.mobileSmall};`
      : `bottom: ${ChargeMediaTheme.padding.small};`}
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0px);
  }
`;

const LogoImage = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  animation: ${slideIn} 1s ease-out 0s;
  pointer-events: none;
`;

const GlobalLogo = ({ visible, isMobile }) => {
  const [logoUrl, setLogoUrl] = useState("");
  const [isModelLoaded, setIsModelLoaded] = useState(false);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setIsModelLoaded(true);
      }, 1550);

      return () => clearTimeout(timeout);
    } else {
      setIsModelLoaded(false);
    }
  }, [visible]);

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setLogoUrl(data.data.attributes.Logo.data.attributes.url);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div>
      {isModelLoaded && (
        <LogoContainer isMobile={isMobile}>
          <a href={WEBSITE_URL}>
            <LogoImage
              src={STRAPI_URL + logoUrl}
              alt="Logo"
              className="logo-image"
            />
          </a>
        </LogoContainer>
      )}
    </div>
  );
};

export default GlobalLogo;
