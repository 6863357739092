import React from "react";
import { IconBox } from "../styled/PortfolioStyled";
import styled, { keyframes } from "styled-components";

const growAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const IconImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
  animation: ${growAnimation} 0.75s ease-in-out;
`;

const PortfolioIcons = ({ iconUrl }) => (
  <IconBox>
    <IconImage src={iconUrl} alt="Icon" />
  </IconBox>
);

export default PortfolioIcons;
