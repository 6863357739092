import React from "react";
import styled from "styled-components";

const SingleLineTextStyle = styled.p`
  text-align: ${(props) => props.textAlign || "center"};
  margin: 0px;
  margin-top: ${(props) => props.margintop || "0px"};
  margin-left: ${(props) => props.marginLeft || "0px"};
  margin-right: ${(props) => props.marginRight || "0px"};
  cursor: ${(props) => props.cursor || "default"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SingleLineText = ({
  text,
  alignment,
  margintop,
  marginLeft,
  marginRight,
  onClick,
  cursor,
  maxLength,
  textAlign,
  color,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Truncate the text if maxLength is specified
  const truncatedText = maxLength
    ? text.slice(0, maxLength) + (text.length > maxLength ? "..." : "")
    : text;

  return (
    <SingleLineTextStyle
      alignment={alignment}
      margintop={margintop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      cursor={cursor}
      textAlign={textAlign}
      onClick={handleClick}
      color={color}
    >
      {truncatedText}
    </SingleLineTextStyle>
  );
};

export default SingleLineText;
