import React from "react";
import styled, { keyframes } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import SingleLineText from "../SingleLineText";
import { ChargeMediaTheme } from "../../ChargemediaTheme";

const { dimens } = ChargeMediaTheme;

const slideIn = keyframes`
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`;

const Button = styled(Link)`
  background-color: ${(props) => props.theme.colors.darkGrey};
  transform: translateX(0px);
  width: 80%;
  height: ${dimens.mobileLongBtnHeight};
  margin-bottom: ${(props) => props.theme.padding.small};
  border-radius: ${(props) => props.theme.dimens.boxRadius};
  border: none;
  color: ${(props) => props.theme.colors.lightGrey};
  transition: ${(props) => props.theme.timings.btnFade} ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  animation: ${(props) => (props.visible ? slideIn : "")}
    ${(props) => props.theme.timings.btnFade} ease-in-out;
  animation-delay: ${(props) => (props.visible ? props.delay : "0s")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.darkGrey};
    transform: translateX(0%);
  }
  &:focus {
    background-color: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.lightGrey};
    transform: translateX(0%);
  }
`;

const MobileBtnLong = ({ onClick, text, to, visible, delay }) => {
  const location = useLocation();
  const isactive = location.pathname === to;

  return (
    <>
      {visible && (
        <Button
          onClick={onClick}
          to={to}
          visible={visible}
          delay={delay}
          activelink={isactive}
        >
          <SingleLineText text={text} cursor={"pointer"} />
        </Button>
      )}
    </>
  );
};

export default MobileBtnLong;
