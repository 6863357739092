import React from "react";
import styled from "styled-components";

const EmailLinkStyle = styled.p`
  text-align: ${(props) => props.alignment || "left"};
  margin: 0px;
  margin-top: ${(props) => props.margintop || "4px"};
  cursor: ${(props) => props.cursor || "default"};

  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;

const EmailLink = ({ text, alignment, margintop, onClick, cursor }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <EmailLinkStyle
      alignment={alignment}
      margintop={margintop}
      cursor={cursor}
      onClick={handleClick}
    >
      {text}
    </EmailLinkStyle>
  );
};

export default EmailLink;
