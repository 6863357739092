import React, { useMemo, useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useSpring, animated } from "@react-spring/three";
import { useFrame } from "@react-three/fiber";
import { SphereGeometry } from "three";
import flags from "./props/flags";
const ModelLoader = ({
  modelPaths,
  position,
  rotation,
  safeAreaPosition = position,
  safeAreaRotation = rotation,
  safeAreaWallWidth = 2,
  safeAreaWallHeight = 2,
  hoverProps,
  onClick,
  isOnClickActive,
  lightPosition = { x: 0, y: 0, z: 0 },
  lightTarget,
  lightAngle,
  lightDecay = 2,
  lightDistance = 0,
  lightPenumbra = 0,
  safeAreaRadius = 0.3,
  safeAreaShape = "sphere",
  shadowBoxes = [],
}) => {
  const [isSafeAreaHovered, setIsSafeAreaHovered] = useState(false); // Track safe area hover state
  const gltf = useLoader(GLTFLoader, modelPaths[0]);
  const gltf2 = useLoader(GLTFLoader, modelPaths[1]);
  const model = useMemo(() => gltf.scene.clone(), [gltf2.scene]);
  const model2 = useMemo(() => gltf2.scene.clone(), [gltf2.scene]);
  const lightWireframeGeometry = useMemo(
    () => new SphereGeometry(0.05, 16, 16),
    []
  );
  const [opacityProps, setOpacityProps] = useSpring(() => ({
    opacity: isSafeAreaHovered ? 1 : 0.5,
    config: { duration: 500 },
  }));
  const pointLightRef = useRef();
  const StaticPointLightRef = useRef();
  const safeAreaRef = useRef();
  const pointLightTarget = useRef();
  const safeAreaVisible = flags.DebugSafeArea;

  const [isHovered, setIsHovered] = useState(false);

  const lightWireFrameVisible = flags.DebugLight;

  const [lightProps, setLightProps] = useSpring(() => ({
    intensity: 0.25,
    config: { duration: 500 },
  }));

  const [staticLightProps, setStaticLightProps] = useSpring(() => ({
    intensity: 0.25,
    config: { duration: 500 },
  }));

  const [lightColor, setLightColor] = useSpring(() => ({
    config: { duration: 500 },
  }));

  useEffect(() => {});

  const handlePointerClick = (e) => {
    e.stopPropagation();
    if (isOnClickActive) {
      onClick && onClick(e);
    }
  };

  const handlePointerOver = (e) => {
    e.stopPropagation();
    if (isOnClickActive) {
      setStaticLightProps({ intensity: 0.8 });
    }
    setIsHovered(true);
    hoverProps.onPointerOver && hoverProps.onPointerOver(e);
  };

  const handlePointerOut = (e) => {
    e.stopPropagation();
    setStaticLightProps({ intensity: 0.25 });
    setIsHovered(false);
    hoverProps.onPointerOut && hoverProps.onPointerOut(e);
  };

  useFrame(() => {
    setLightProps((prevProps) => ({
      intensity: 0.3 + 0.3 * Math.sin(0.06 * Date.now()),
    }));

    setOpacityProps({ opacity: isSafeAreaHovered ? 1 : 0.5 });
  });

  return (
    <group>
      {!isHovered && (
        <mesh
          position={position}
          rotation={rotation}
          onPointerOver={handlePointerOver}
          onPointerOut={handlePointerOut}
          onClick={handlePointerClick}
          material-color={`rgba(255, 255, 255, ${opacityProps.opacity})`}
          castShadow={false}
        >
          <primitive object={model}>
            <meshStandardMaterial
              castShadow={false}
              attach="material"
              shadowSide={THREE.FrontSide}
            />
          </primitive>
        </mesh>
      )}
      {isHovered && (
        <mesh
          position={position}
          rotation={rotation}
          onPointerOver={handlePointerOver}
          onPointerOut={handlePointerOut}
          onClick={handlePointerClick}
          material-color={`rgba(255, 255, 255, ${opacityProps.opacity})`}
          castShadow={false}
        >
          <primitive object={model2}>
            <meshStandardMaterial
              castShadow={false}
              attach="material"
              shadowSide={THREE.FrontSide}
            />
          </primitive>
        </mesh>
      )}
      <>
        {safeAreaShape === "sphere" && (
          <mesh
            ref={safeAreaRef}
            position={safeAreaPosition}
            rotation={safeAreaRotation}
            visible
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            onClick={handlePointerClick}
            castShadow={false}
          >
            <sphereGeometry args={[safeAreaRadius, 16, 16]} />

            {safeAreaVisible && (
              <meshBasicMaterial color="grey" transparent opacity={0.1} />
            )}
            {!safeAreaVisible && (
              <meshBasicMaterial color="grey" transparent opacity={0} />
            )}
          </mesh>
        )}

        {shadowBoxes.map((box, index) => (
          <React.Fragment key={index}>{box}</React.Fragment>
        ))}
        {safeAreaShape === "plane" && (
          <mesh
            ref={safeAreaRef}
            position={safeAreaPosition}
            rotation={safeAreaRotation}
            visible
            onPointerOver={handlePointerOver}
            onPointerOut={handlePointerOut}
            onClick={handlePointerClick}
            castShadow={false}
          >
            <boxGeometry
              args={[
                safeAreaRadius * safeAreaWallWidth,
                safeAreaRadius * safeAreaWallHeight,
                0.1,
              ]}
            />
            {safeAreaVisible && (
              <meshBasicMaterial color="grey" transparent opacity={0.1} />
            )}
            {!safeAreaVisible && (
              <meshBasicMaterial color="grey" transparent opacity={0} />
            )}
          </mesh>
        )}
      </>
      <animated.pointLight
        ref={pointLightRef}
        position={lightPosition}
        color={lightColor.color}
        intensity={lightProps.intensity}
        distance={1}
      />
      <animated.spotLight
        ref={pointLightRef}
        position={lightPosition}
        color={lightColor.color}
        intensity={staticLightProps.intensity}
        distance={lightDistance}
        angle={lightAngle}
        castShadow={false}
        decay={lightDecay}
        target={pointLightTarget.current}
        penumbra={lightPenumbra}
      />
      <mesh
        ref={pointLightTarget}
        position={lightTarget}
        rotation={safeAreaRotation}
        visible
      >
        <sphereGeometry args={[safeAreaRadius, 16, 16]} />
        <meshBasicMaterial transparent opacity={0} />
      </mesh>
      {lightWireFrameVisible && (
        <lineSegments position={lightTarget}>
          <edgesGeometry args={[lightWireframeGeometry]} />
          <lineBasicMaterial color={"blue"} linewidth={1} />
        </lineSegments>
      )}
      {lightWireFrameVisible && (
        <lineSegments position={lightPosition}>
          <edgesGeometry args={[lightWireframeGeometry]} />
          <lineBasicMaterial color={"red"} linewidth={1} />
        </lineSegments>
      )}
      ;
    </group>
  );
};

export default ModelLoader;
