import React from "react";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";

const RichTextContainer = styled.div`
  & p {
    margin-bottom: 1rem;
  }
  & ul {
    margin-bottom: 1rem;
    list-style: disc;
    padding-left: 1.5rem;
  }
  & ol {
    margin-bottom: 1rem;
    list-style: decimal;
    padding-left: 1.5rem;
  }
`;

const RichText = ({ content }) => (
  <RichTextContainer>
    <ReactMarkdown>{content}</ReactMarkdown>
  </RichTextContainer>
);

export default RichText;
