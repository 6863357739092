import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  color: #fff;
  font-size: 24px;
  z-index: 9999;
`;

const Spinner = styled.div`
  margin-bottom: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: transparent;
  animation: ${spin} 1s linear infinite;
`;

const LoadingScreen = () => {
  return (
    <LoadingContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Spinner />
        <div>Loading...</div>
      </div>
    </LoadingContainer>
  );
};

export default LoadingScreen;
