import React from "react";
import styled from "styled-components";

const SingleLineH2Style = styled.h2`
  text-align: ${(props) => props.alignment || "left"};
`;

const SingleLineH2 = ({ text, alignment }) => {
  return <SingleLineH2Style alignment={alignment}>{text}</SingleLineH2Style>;
};

export default SingleLineH2;
