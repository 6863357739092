import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import CookieConsent from "react-cookie-consent";
import { ChargeMediaTheme } from "./ChargemediaTheme";

const { colors } = ChargeMediaTheme;
const slideIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;
const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  display: flex;
  font-size: 18px;
  justify-content: space-between;
  align-items: center;
  animation: ${slideIn} 0.5s ease-in-out;
  z-index: 999;
  @media (max-width: 865px) {
    height: auto;
  }
`;
const Message = styled.p`
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AcceptButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 30px;
`;

const PolicyLink = styled.a`
  color: ${colors.red};
  text-decoration: none;
  margin-left: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const RefuseButton = styled.button`
  background-color: ${colors.red};
  color: white;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  margin-right: 10px;
`;

const Cookies = () => {
  const [isCookieAccepted, setIsCookieAccepted] = useState(
    localStorage.getItem("user-has-accepted-cookies") === "true"
  );

  const handleAccept = () => {
    localStorage.setItem("user-has-accepted-cookies", "true");
    setIsCookieAccepted(true);
  };

  const handleRefuse = () => {
    // You can customize the behavior when the user refuses cookies.
    // For example, you might want to block certain services or show a different message.
    localStorage.setItem("user-has-accepted-cookies", "false");
    setIsCookieAccepted(true); // Set to true for demonstration purposes, adjust as needed.
  };

  return !isCookieAccepted ? (
    <BannerContainer>
      <Message>
        Solution Dynamic nutzt keine Cookies, außer für Diese Zustimmung.{" "}
        <PolicyLink href="/policy">Datenschutzerklärung</PolicyLink>
      </Message>
      <ButtonContainer>
        <ButtonContainer>
          <RefuseButton onClick={handleRefuse}>Ablehnen</RefuseButton>
          <AcceptButton onClick={handleAccept}>Akzeptieren</AcceptButton>
        </ButtonContainer>
      </ButtonContainer>
    </BannerContainer>
  ) : null;
};
export default Cookies;
