import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import SingleLineText from "./SingleLineText";

const slideIn = keyframes`
  0% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(-10px);
    opacity: 1;
  }
`;

const Button = styled(Link)`
  background-color: ${(props) =>
    props.ishighlighted
      ? props.theme.colors.darkGrey
      : props.theme.colors.lightGrey};
  transform: ${(props) =>
    props.ishighlighted ? "translateX(0px)" : "translateX(-10px)"};
  width: ${(props) => (props.ishighlighted ? "160px;" : "150px;")};
  height: 40px;
  margin-left: ${(props) => (props.activelink ? 0 : "-10px")};
  border-radius: 0 ${(props) => props.theme.dimens.boxRadius}
    ${(props) => props.theme.dimens.boxRadius} 0;
  border: none;
  color: ${(props) =>
    props.ishighlighted
      ? props.theme.colors.lightGrey
      : props.theme.colors.darkGrey};
  transition: ${(props) => props.theme.timings.btnFade} ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  animation: ${(props) => (props.visible ? slideIn : "")}
    ${(props) => props.theme.timings.btnFade} ease-in-out;
  animation-delay: ${(props) => (props.visible ? props.delay : "0s")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.lightGrey};
    transform: ${(props) =>
      props.ishighlighted ? "translateX(0%)" : "translateX(0%)"};
  }
  &:focus {
    background-color: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.lightGrey};
    transform: translateX(0%);
  }
`;

const XylophoneMenuBtn = ({ onClick, text, to, visible, delay }) => {
  const location = useLocation();
  const [ishighlighted, setishighlighted] = useState(false);

  useEffect(() => {
    setishighlighted(location.pathname.startsWith(to));
  }, [location.pathname, to]);

  return (
    <>
      {visible && (
        <Button
          onClick={onClick}
          to={to}
          visible={visible}
          delay={delay}
          ishighlighted={ishighlighted}
        >
          <SingleLineText text={text} cursor={"pointer"} />
        </Button>
      )}
    </>
  );
};
export default XylophoneMenuBtn;
