import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import SingleLineText from "../SingleLineText";

const slideInAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
`;

const Button = styled(Link)`
  background-color: ${(props) =>
    props.isIcon ? props.theme.colors.darkGrey : props.theme.colors.darkGrey};
  width: ${(props) =>
    props.isIcon ? "50px" : props.isMobile ? "25vw" : "125px"};
  height: ${(props) =>
    props.isIcon ? "50px" : props.height ? props.height : "50px"};
  border-radius: ${(props) =>
    props.isIcon ? "50%" : `0 ${props.theme.dimens.boxRadius} 0 0`};
  border: none;
  color: ${(props) =>
    props.isIcon ? props.theme.colors.lightGrey : props.theme.colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: ${(props) => props.theme.timings.btnFade} ease-out;
  animation: ${slideInAnimation} ${(props) => props.theme.timings.btnFade}
    ease-out;
  animation-fill-mode: both;
  opacity: 0;
  &:hover {
    background-color: ${(props) =>
      props.isIcon
        ? props.theme.colors.darkGrey
        : props.theme.colors.lightGrey};
    color: ${(props) =>
      props.isIcon
        ? props.theme.colors.lightGrey
        : props.theme.colors.darkGrey};
  }
`;

const ImprintButton = ({
  text,
  to,
  onClick,
  delay,
  visible,
  isMobile,
  isIcon,
}) => {
  const [animationDelay, setAnimationDelay] = useState(delay || 0);
  const [isModelLoaded, setIsModelLoaded] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimationDelay(0);
    }, animationDelay);

    return () => clearTimeout(timeout);
  }, [animationDelay]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setIsModelLoaded(true);
      }, 500);

      return () => clearTimeout(timeout);
    } else {
      setIsModelLoaded(false);
    }
  }, [visible]);

  return (
    <div>
      {isModelLoaded && (
        <Button
          onClick={handleClick}
          to={to}
          style={{ animationDelay: `${animationDelay}ms` }}
          isMobile={isMobile}
          isIcon={isIcon}
        >
          <SingleLineText text={text} cursor={"pointer"} />
        </Button>
      )}
    </div>
  );
};

export default ImprintButton;
