const initialCameraPos = { x: 12, y: 1, z: 12 };
const initialCameraRot = { x: 0, y: 0, z: 0 };
const initialCameraPosMobile = { x: 9.8, y: 1.5, z: 14 };
const initialCameraRotMobile = { x: -0.25, y: 0, z: 0 };
const PortCameraPos = { x: 10.4, y: 0.8, z: 10.5 };
const PortCameraRot = { x: -0.3, y: 0, z: 0 };
const OffCameraPos = { x: 10.4, y: 0.8, z: 10.5 };
const OffCameraRot = { x: -0.3, y: 0, z: 0 };
const AboutCameraPos = { x: 12.15, y: 0.5, z: 6.5 };
const AboutCameraRot = { x: 0, y: 0, z: 0 };
const ImprintCameraPos = { x: 12, y: 15, z: 17 };
const ImprintCameraRot = { x: -0.75, y: 0.0, z: 0 };
const PluginsCameraPos = { x: 10.44, y: 0.35, z: 6.825 };
const PluginsCameraRot = { x: 0, y: 0.2825, z: 0 };
const WebsitesCameraPos = { x: 8.85, y: 0.5, z: 7.8 };
const WebsitesCameraRot = { x: 0, y: 1.11, z: 0 };
const PrintCameraPos = { x: 10.1, y: 1, z: 8.922 };
const PrintCameraRot = { x: -1.5, y: 0.01, z: 0.65 };
const VideoCameraPos = { x: 10.95, y: 0.12, z: 8.35 };
const VideoCameraRot = { x: 0 / 100, y: 36.5 / 100, z: 0 };

module.exports = {
  initialCameraPos,
  initialCameraRot,
  initialCameraPosMobile,
  initialCameraRotMobile,
  PortCameraPos,
  PortCameraRot,
  OffCameraPos,
  OffCameraRot,
  AboutCameraPos,
  AboutCameraRot,
  ImprintCameraPos,
  ImprintCameraRot,
  PluginsCameraPos,
  PluginsCameraRot,
  WebsitesCameraPos,
  WebsitesCameraRot,
  PrintCameraPos,
  PrintCameraRot,
  VideoCameraPos,
  VideoCameraRot,
};
