import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import styled from "styled-components";
import "react-pdf/dist/esm/Page/TextLayer.css";

import { ArrowButton } from "./ArrowButton";
import ArrowButtonText from "./ArrowButtonText";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import LoadingIndicator from "./LoadingIndicator";

const { colors, padding } = ChargeMediaTheme;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${padding.small};
`;

const PdfContent = styled.div`
  margin: 0px 0;
`;

const PdfViewer = ({ pdfUrl }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === numPages;

  return (
    <PdfContainer>
      <PdfContent>
        <Document
          backgroundColor={colors.darkGrey}
          loading={<LoadingIndicator />}
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={currentPage} width={800} />
        </Document>
      </PdfContent>
      <PaginationContainer>
        {!isFirstPage && (
          <ArrowButton
            direction="down"
            onClick={handlePreviousPage}
            color={colors.lightGrey}
            hoverColor={colors.darkGrey}
            disabled={isFirstPage}
          />
        )}
        <ArrowButtonText
          currentIndex={currentPage}
          totalIndex={numPages}
          color={colors.lightGrey}
        />
        {!isLastPage && (
          <ArrowButton
            direction="up"
            onClick={handleNextPage}
            color={colors.lightGrey}
            hoverColor={colors.darkGrey}
          />
        )}
      </PaginationContainer>
    </PdfContainer>
  );
};

export default PdfViewer;
