import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import your i18n configuration
import { auth, firestore } from "./firebase"; // Import the Firebase configuration

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log = console.warn = console.error = () => {};

function Index() {
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Reference to the Firestore document you want to check
    // Reference to the Firestore document you want to check
    const documentRef = firestore
      .collection("soldyn")
      .doc("5kB2Cpnj25ADUOPPCOy2");

    // Listen to changes in the document
    const unsubscribe = documentRef.onSnapshot((snapshot) => {
      const data = snapshot.data();
      if (data && data.valid === true) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <React.StrictMode>{isValid && <App />}</React.StrictMode>
    </I18nextProvider>
  );
}

root.render(<Index />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
