import React, { Children } from "react";
import ModelLoader from "../ModelLoader";
import flags from "./flags";
function ModelLoaders({
  page,
  t,
  handleModelHover,
  handleModelHoverOut,
  handleModelPluginClick,
  handleModelPrintClick,
  handleModelVideoClick,
  handleModelWebsiteClick,
  handleModelAboutClick,
  isModelOnClickActive,
}) {
  return (
    <>
      <ModelLoader
        page={page}
        modelPaths={["/models/plugins.gltf", "/models/pluginsHover.gltf"]}
        modelpage={"plugins"}
        textRotation={[0, 0.5, 0]}
        position={[10.28, 0, 6.4]}
        safeAreaPosition={[10.3, 0.2, 6.4]}
        safeAreaRotation={[0, 0.4, 0]}
        safeAreaShape={"plane"}
        safeAreaRadius={0.3}
        rotation={[0, 0, 0]}
        lightPosition={[10.325, 0.5, 6.5]}
        lightTarget={[10.325, 0.0, 6.5]}
        lightAngle={Math.PI / 2}
        lightDistance={1}
        lightPenumbra={0.5}
        hoverProps={{
          onPointerOver: handleModelHover,
          onPointerOut: handleModelHoverOut,
        }}
        onClick={handleModelPluginClick}
        isOnClickActive={isModelOnClickActive}
      />
      <ModelLoader
        page={page}
        modelpage={"print"}
        modelPaths={["/models/print.gltf", "/models/printHover.gltf"]}
        textRotation={[0, 0.5, 0]}
        position={[10.05, 0, 8.915]}
        safeAreaPosition={[10.1, 0, 8.915]}
        safeAreaWallWidth={3}
        safeAreaWallHeight={2.89}
        safeAreaRotation={[0, 0.65, 0]}
        safeAreaRadius={0.3}
        safeAreaShape={"plane"}
        rotation={[0, 0, 0]}
        lightPosition={[10.05, 0.6, 8.915]}
        lightTarget={[10.05, 0.28, 8.915]}
        lightAngle={Math.PI / 2.5}
        lightDistance={2}
        lightPenumbra={0.5}
        hoverProps={{
          onPointerOver: handleModelHover,
          onPointerOut: handleModelHoverOut,
        }}
        onClick={handleModelPrintClick}
        isOnClickActive={isModelOnClickActive}
      ></ModelLoader>
      <ModelLoader
        page={page}
        modelpage={"video"}
        modelPaths={["/models/video.gltf", "/models/videoHover.gltf"]}
        textRotation={[0, 0.5, 0]}
        position={[10.9, 0.0, 8]}
        safeAreaPosition={[10.85, 0.06, 8]}
        safeAreaRadius={0.25}
        rotation={[0, 0, 0]}
        lightPosition={[10.8, 0.11, 8.04]}
        lightTarget={[11.33, 0.11, 9.1]}
        lightAngle={Math.PI / 2.5}
        lightDistance={2}
        lightPenumbra={0.5}
        hoverProps={{
          onPointerOver: handleModelHover,
          onPointerOut: handleModelHoverOut,
        }}
        onClick={handleModelVideoClick}
        isOnClickActive={isModelOnClickActive}
      />
      <ModelLoader
        page={page}
        modelpage={"websites"}
        modelPaths={["/models/web.gltf", "/models/webHover.gltf"]}
        textRotation={[0, 0.5, 0]}
        position={[8.6, 0.0, 7.5]}
        safeAreaPosition={[8.75, 0.3, 7.5]}
        safeAreaRadius={0.3}
        safeAreaShape={"plane"}
        safeAreaWallWidth={3}
        safeAreaWallHeight={2}
        safeAreaRotation={[0, 1, 0]}
        rotation={[0, 0, 0]}
        lightPosition={[8.55, 0.5, 7.65]}
        lightTarget={[9.4, 0.5, 8]}
        lightAngle={Math.PI / 3}
        lightDistance={2}
        lightPenumbra={0.5}
        hoverProps={{
          onPointerOver: handleModelHover,
          onPointerOut: handleModelHoverOut,
        }}
        onClick={handleModelWebsiteClick}
        isOnClickActive={isModelOnClickActive}
      />
      {/* About Chairs */}
      <ModelLoader
        page={page}
        modelpage={"about"}
        modelPaths={["/models/chair.gltf", "/models/chairHover.gltf"]}
        text={t("about")}
        textRotation={[0, 0.5, 0]}
        position={[11.8, 0, 5.4]}
        rotation={[0, 89.9, 0]}
        safeAreaPosition={[11.8, 0.2, 5.4]}
        safeAreaRotation={[0, 0.4, 0]}
        safeAreaShape={"sphere"}
        safeAreaRadius={0.5}
        lightPosition={[11.8, 1, 5.4]}
        lightTarget={[11.8, 0, 5.4]}
        lightAngle={Math.PI / 2}
        lightDistance={2}
        lightPenumbra={0.5}
        hoverProps={{
          onPointerOver: handleModelHover,
          onPointerOut: handleModelHoverOut,
        }}
        onClick={handleModelAboutClick}
        isOnClickActive={isModelOnClickActive}
      />
      {/* SafeAreas */}
    </>
  );
}

export default ModelLoaders;
