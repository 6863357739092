export const ChargeMediaTheme = {
  colors: {
    lightGrey: "#D9D9D9",
    darkGrey: "#292C2C",
    red: "#C0392B",
  },
  padding: {
    small: "15px",
    mobileSmall: "8px",
    medium: "30px",
    large: "145px",
  },
  timings: {
    btnFade: "0.275s",
    ResponsiveDesignswitch: "0.575s",
  },
  dimens: {
    boxRadius: "5px",
    mobileLongBtnHeight: "40px",
    mobileBtnHeight: "55px",
    mobileNavbarHeight: "60px",
  },
  boolean: {
    isMainMenuVis: false,
  },
};
