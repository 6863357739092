import React, { useState, useEffect } from "react";
import styled from "styled-components";
import GridItem from "./ui/GridItem";
import { ChargeMediaTheme } from "./ChargemediaTheme";
import { ArrowButton } from "./ui/ArrowButton";
import ArrowButtonText from "./ui/ArrowButtonText";
const { colors } = ChargeMediaTheme;
const { padding } = ChargeMediaTheme;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${padding.large};
`;

const GridContainer = styled.div`
  width: ${(props) => {
    if (props.itemsLength === 1) {
      return "70%;";
    } else {
      return "100%;";
    }
  }};

  height: ${(props) => {
    if (props.itemsLength === 1) {
      return "70%;";
    } else if (props.itemsLength === 2) {
      return "70%;";
    } else if (props.itemsLength === 3) {
      return "45%;";
    } else {
      return "100%;";
    }
  }};
  margin-top: ${(props) => {
    if (props.itemsLength === 1) {
      return "5%;";
    } else if (props.itemsLength === 2) {
      return "5%;";
    } else if (props.itemsLength === 3) {
      return "10%;";
    } else {
      return "0%;";
    }
  }};
  margin-left: ${(props) => {
    if (props.itemsLength === 1) {
      return "15%;";
    } else {
      return "0%;";
    }
  }};
  background-color: ${colors.darkGrey};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to start of the row */
  gap: 10px; /* Add some gap between grid items */
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isChangingPage ? 0 : 1)};

  /* Set flex-basis to ensure three items per row */
  & > * {
    flex: calc(33.33% - 10px);
  }

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 100%;
    height: 33.33%;
    display: flex;
    flex-direction: column;
  }
`;

const GridContainerMobile = styled.div`
  width: 100%;
  height: 100% !important;
  margin-top: 0%;
  background-color: ${colors.darkGrey};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items to start of the row */
  gap: 10px; /* Add some gap between grid items */
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isChangingPage ? 0 : 1)};

  /* Set flex-basis to ensure three items per row */
  & > * {
    flex: calc(33.33% - 10px);
  }

  @media (max-width: 1400px) {
  }

  @media (max-width: 865px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const GridviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-y: auto;

  /* Hide scrollbar but keep functionality - Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  /* Hide scrollbar - WebKit (Safari and Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Gridview = ({
  items,
  itemsLength,
  pageSize,
  onClick,
  imgUrl,
  title,
  to,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isChangingPage, setIsChangingPage] = useState(false);
  const totalPages = Math.ceil(items.length / pageSize);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    // Update isMobile state based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 865);
    };
    handleResize(); // Initialize isMobile state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handlePageChange = (page) => {
    setIsChangingPage(true);
    setTimeout(() => {
      setCurrentPage(page);
      setIsChangingPage(false);
    }, 300);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentItems = items.slice(startIndex, endIndex);
  console.log("Current Items:", itemsLength);
  return (
    <GridviewContainer>
      {isMobile && (
        <GridContainerMobile
          isChangingPage={isChangingPage}
          itemsLength={itemsLength}
        >
          {currentItems.map((item, index) => (
            <GridItem
              key={startIndex + index}
              animationType={isChangingPage ? "fadeOut" : "fadeIn"}
              animationDelay={index * 0.1}
              title={title(index)}
              imageUrl={imgUrl(index)}
              onClick={() => onClick(item)}
              to={() => to(index)}
            >
              {item}
            </GridItem>
          ))}
        </GridContainerMobile>
      )}
      {!isMobile && (
        <GridContainer
          isChangingPage={isChangingPage}
          itemsLength={itemsLength}
        >
          {currentItems.map((item, index) => (
            <GridItem
              key={startIndex + index}
              animationType={isChangingPage ? "fadeOut" : "fadeIn"}
              animationDelay={index * 0.1}
              title={title(index)}
              imageUrl={imgUrl(index)}
              onClick={() => onClick(item)}
              to={() => to(index)}
            >
              {item}
            </GridItem>
          ))}
        </GridContainer>
      )}
      {totalPages > 1 && (
        <PaginationContainer>
          <ArrowButton
            direction="down"
            onClick={goToPrevPage}
            color={colors.lightGrey}
            hoverColor={colors.darkGrey}
          />

          <ArrowButtonText
            currentIndex={currentPage}
            totalIndex={totalPages}
            color={colors.lightGrey}
          />
          <ArrowButton
            direction="up"
            onClick={goToNextPage}
            color={colors.lightGrey}
            hoverColor={colors.darkGrey}
          />
        </PaginationContainer>
      )}
    </GridviewContainer>
  );
};
export default Gridview;
