import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import SingleLineText from "../SingleLineText";

const Button = styled(Link)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  width: 33.33%;
  height: 100%;
  margin-left: ${(props) => (props.activelink ? 0 : "-10px")};
  border-bottom: ${(props) =>
    props.ishighlighted ? `5px solid ${props.theme.colors.darkGrey}` : "none"};
  color: ${(props) => props.theme.colors.darkGrey};
  transition: ${(props) => props.theme.timings.btnFade} ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  animation-delay: ${(props) => (props.visible ? props.delay : "0s")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  &:hover {
  }
  &:focus {
    color: ${(props) => props.theme.colors.darkGrey};
    transform: translateX(0%);
  }
`;

const MobileBtn = ({ onClick, text, to, visible, delay }) => {
  const location = useLocation();
  const [ishighlighted, setishighlighted] = useState(false);

  useEffect(() => {
    setishighlighted(location.pathname.startsWith(to));
  }, [location.pathname, to]);

  return (
    <>
      {visible && (
        <Button
          onClick={onClick}
          to={to}
          visible={visible}
          delay={delay}
          ishighlighted={ishighlighted}
        >
          <SingleLineText text={text} cursor={"pointer"} />
        </Button>
      )}
    </>
  );
};
export default MobileBtn;
