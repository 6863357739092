import React from "react";
import styled from "styled-components";

const SingleLineH3Style = styled.h3`
  text-align: ${(props) => props.alignment || "left"};
  margin: 0px;
  margin-top: ${(props) => props.margintop || "0px"};
`;

const SingleLineH3 = ({ text, alignment, margintop }) => {
  return (
    <SingleLineH3Style alignment={alignment} margintop={margintop}>
      {text}
    </SingleLineH3Style>
  );
};

export default SingleLineH3;
