import React from "react";
import styled from "styled-components";

const SingleLineH1Style = styled.h1`
  text-align: ${(props) => props.alignment || "left"};
  margin: 0px;
  margin-top: ${(props) => props.margintop || "0px"};
`;

const SingleLineH1 = ({ text, alignment, margintop }) => {
  return (
    <SingleLineH1Style alignment={alignment} margintop={margintop}>
      {text}
    </SingleLineH1Style>
  );
};

export default SingleLineH1;
