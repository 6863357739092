import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import XylophoneMenuBtn from "./ui/XylophoneMenuBtn";
import XylophoneMenuMiniBtn from "./ui/XylophoneMenuMiniBtn";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const StyledXylophoneMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.padding.small};
  justify-content: center;
  position: fixed;
  top: ${(props) => props.theme.padding.medium};
  left: 0;
  transform: translateY(-50%);

  /* Animation for appearing and disappearing */
  ${(props) =>
    props.visible
      ? css`
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.375s ease, transform 0.3s ease;
        `
      : css`
          opacity: 0;
          transform: translateX(-20px);
          transition: opacity 0.375s ease, transform 0.3s ease;
          pointer-events: none; /* Disable interaction during the transition */
        `}
`;

const XylophoneMenu = ({
  onClick,
  textPort,
  toPort,
  textOff,
  toOff,
  textAbout,
  toAbout,
  visible,
}) => {
  const location = useLocation();
  const isPortfolioPage =
    location.pathname === "/portfolio" ||
    location.pathname.startsWith("/portfolio/");

  const isOffersPage =
    location.pathname === "/offer" || location.pathname.startsWith("/offer/");

  const [renderPort, setRenderPort] = useState(false);
  const [renderOff, setRenderOff] = useState(false);
  const [renderAbout, setRenderAbout] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setRenderPort(true);
      }, 500);

      return () => clearTimeout(timeout);
    } else {
      setRenderPort(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setRenderOff(true);
      }, 1000);

      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setRenderOff(false);
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setRenderAbout(true);
      }, 1100);

      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setRenderAbout(false);
      }, 1100);

      return () => clearTimeout(timeout);
    }
  }, [visible]);

  return (
    <StyledXylophoneMenu visible={visible}>
      {/* Show the MiniButton on "/portfolio" and "/portfolio/*" pages */}

      <XylophoneMenuBtn
        onClick={onClick}
        text={textPort}
        to={toPort}
        visible={renderPort}
        delay={"0"}
      />
      {isPortfolioPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("web")}
          to={toPort + "/websites"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      {isPortfolioPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("print")}
          to={toPort + "/print"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      {isPortfolioPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("video")}
          to={toPort + "/video"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      {isPortfolioPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("plugins")}
          to={toPort + "/plugins"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      <XylophoneMenuBtn
        onClick={onClick}
        text={textOff}
        to={toOff}
        visible={renderOff}
        delay={"0"}
      />
      {isOffersPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("web")}
          to={toOff + "/websites"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      {isOffersPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("print")}
          to={toOff + "/print"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      {isOffersPage && (
        <XylophoneMenuMiniBtn
          onClick={onClick}
          text={t("video")}
          to={toOff + "/video"}
          visible={renderPort}
          delay={"0"}
        />
      )}
      <XylophoneMenuBtn
        onClick={onClick}
        text={textAbout}
        to={toAbout}
        visible={renderAbout}
        delay={"0"}
      />
    </StyledXylophoneMenu>
  );
};

export default XylophoneMenu;
