import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CenteredBox,
  BoxContainer,
  ContentBox,
  TitleBox,
  CurrentPageBox,
  BoxRow,
  BoxContentDiv,
  Inner,
  ContentLeft,
  ContentRight,
  InfoTextBox,
  PictureAndText,
  AboutTextBox,
} from "../styled/AboutUsStyled";
import "./portfolio/portfolStyles.css";
import BackButton from "../ui/BackButton";
import flags from "../props/flags";
import Gridview from "../Gridview";
import { API_URL, WEBSITE_URL, STRAPI_URL } from "../../config";
import { useTranslation } from "react-i18next";
import GridItem from "../ui/GridItem";
import MiniGallery from "../ui/MiniGallery";
import MiniGallerySingle from "../ui/MiniGallerySingle";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import SingleLineText from "../ui/SingleLineText";
import EmailLink from "../ui/EmailLink";
import RichText from "../ui/RichText";

const { padding } = ChargeMediaTheme;

const AboutUs = ({ toggleMainMenu }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [fadeOut, setFadeOut] = useState(false);
  const [isBackButtonActive, setIsBackBtnActive] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const isModelLoaded = flags.isModelLoaded;
  //
  const apiUrl = `${API_URL}/api/vertreters?populate=*`;
  const [items, setItems] = useState([]);

  //
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 865);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    toggleMainMenu(true);
    setIsBackBtnActive(true);
  }, []);

  const handleGoBack = () => {
    setFadeOut(true);
    toggleMainMenu(true);
    setTimeout(() => navigate(-1), 700); // Wait for 1 second (animation duration) before navigating back
  };

  const handleGridItemClick = (id, avatarUrl) => {};

  useEffect(() => {
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("Data:", data);
        if (Array.isArray(data.data)) {
          const items = data.data.map((item) => {
            return {
              id: item.id,
              name: item.attributes.Name,
              position: item.attributes.Position,
              telefon: item.attributes.Telefon,
              email: item.attributes.Email,
              about: item.attributes.About,
              avatarUrl: item.attributes.Bild.data.attributes.url,
            };
          });
          setItems(items);
        } else {
          console.error("ABOUT US is not an array:", data.data.Thumbnail.data);
        }
      })
      .catch((error) => console.error(error));
  }, []);
  const id = items.map((item) => item.id);
  const titles = items.map((item) => item.name);
  const positions = items.map((item) => item.position);
  const phones = items.map((item) => item.telefon);
  const emails = items.map((item) => item.email);
  const abouts = items.map((item) => item.about);
  const avatarURLs = items.map((item) => item.avatarUrl);

  return (
    <div>
      <div
        className={`websites-container ${
          fadeOut ? "box-fade-out" : "box-fade-in"
        }`}
      >
        {isModelLoaded && (
          <CenteredBox>
            <BoxContainer>
              <Inner>
                <BoxRow>
                  {!isMobile && <TitleBox>{titles[0]}</TitleBox>}{" "}
                  {!isMobile && <TitleBox>{titles[1]}</TitleBox>}
                </BoxRow>
                <BoxContentDiv>
                  <ContentBox>
                    <ContentLeft>
                      <PictureAndText>
                        <MiniGallerySingle
                          marginLeft={padding.small}
                          image={STRAPI_URL + avatarURLs[0]}
                          width="156px"
                          height="140px"
                        />
                        <InfoTextBox>
                          {isMobile && (
                            <SingleLineText
                              text={titles[0]}
                              textAlign={"left"}
                            />
                          )}
                          <SingleLineText
                            text={positions[0]}
                            textAlign={"left"}
                          />
                          <EmailLink
                            alignment={"left"}
                            text={emails[0]}
                            cursor={"pointer"}
                            onClick={() => {
                              window.location.href = `mailto:${emails[0]}`;
                            }}
                          />
                        </InfoTextBox>
                      </PictureAndText>
                      <AboutTextBox>
                        <RichText content={abouts[0]} />
                      </AboutTextBox>
                    </ContentLeft>
                    <ContentRight>
                      <PictureAndText>
                        <MiniGallerySingle
                          marginLeft={padding.small}
                          image={STRAPI_URL + avatarURLs[1]}
                          width="156px"
                          height="140px"
                        />
                        <InfoTextBox>
                          {isMobile && (
                            <SingleLineText
                              text={titles[1]}
                              textAlign={"left"}
                            />
                          )}

                          <SingleLineText
                            text={positions[1]}
                            textAlign={"left"}
                          />
                          <EmailLink
                            alignment={"left"}
                            text={emails[1]}
                            cursor={"pointer"}
                            onClick={() => {
                              window.location.href = `mailto:${emails[1]}`;
                            }}
                          />
                        </InfoTextBox>
                      </PictureAndText>
                      <AboutTextBox>
                        <RichText content={abouts[1]} />
                      </AboutTextBox>
                    </ContentRight>
                  </ContentBox>
                </BoxContentDiv>
              </Inner>
            </BoxContainer>
          </CenteredBox>
        )}
      </div>
      <BackButton
        handleGoBack={handleGoBack}
        isactive={isBackButtonActive}
        setisactive={setIsBackBtnActive}
      />
    </div>
  );
};

export default AboutUs;
