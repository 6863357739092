import React from "react";
import styled, { keyframes } from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import ArrowIcon from "../shapes/ArrowIcon";
import SingleLineText from "../ui/SingleLineText";

const { colors, padding } = ChargeMediaTheme;

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInFromRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const PaginationButton = styled.div`
  position: relative;
  width: 100%;
  height: 40px;

  background-color: ${(props) => props.color || colors.darkGrey};
  border-radius: 0 5px 5px 0;
  display: flex;
  justify-content: ${(props) =>
    props.isRightSided ? "flex-end" : "flex-start"};
  /* Use "flex-end" to align to the right, "flex-start" to align to the left */
  margin: 0 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor}; /* Replace with your desired hover color */
    color: ${(props) => props.color};
  }

  animation: ${(props) =>
      props.isRightSided ? slideInFromRight : slideInFromLeft}
    0.5s ease;
`;

const PaginationButtonRight = styled.div`
  position: relative;
  width: 100%;
  height: 40px;

  background-color: ${(props) => props.color || colors.darkGrey};
  border-radius: 5px 0px 0px 5px;
  display: flex;
  justify-content: ${(props) =>
    props.isRightSided ? "flex-end" : "flex-start"};
  /* Use "flex-end" to align to the right, "flex-start" to align to the left */
  margin: 0 0px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.hoverColor}; /* Replace with your desired hover color */
    color: ${(props) => props.color};
  }

  animation: ${(props) =>
      props.isRightSided ? slideInFromRight : slideInFromLeft}
    0.5s ease;
`;

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => (props.isRightSided ? "0" : "0")};
  margin-left: ${(props) => (props.isRightSided ? "0" : "0")};
`;

export const LongButton = ({
  direction,
  onClick,
  color,
  colorFade,
  hoverColor,
  text,
  isRightSided = false,
  maxLength, // Add a new prop for maxLength
}) => {
  return (
    <div>
      {isRightSided && (
        <PaginationButtonRight
          onClick={onClick}
          color={color}
          hoverColor={hoverColor}
          style={{ transition: `background-color ${colorFade || 0.3}s ease` }}
          isRightSided={isRightSided}
        >
          <SingleLineText
            text={text}
            maxLength={maxLength}
            cursor={"pointer"}
            margintop={"6px"}
            marginLeft={padding.small}
          />
          <ArrowIconWrapper isRightSided={isRightSided}>
            <ArrowIcon direction={direction} hoverColor={hoverColor} />
          </ArrowIconWrapper>
        </PaginationButtonRight>
      )}
      {!isRightSided && (
        <PaginationButton
          onClick={onClick}
          color={color}
          hoverColor={hoverColor}
          style={{ transition: `background-color ${colorFade || 0.3}s ease` }}
          isRightSided={isRightSided}
        >
          <ArrowIconWrapper isRightSided={isRightSided}>
            <ArrowIcon direction={direction} hoverColor={hoverColor} />
          </ArrowIconWrapper>
          <SingleLineText
            text={text}
            maxLength={maxLength}
            cursor={"pointer"}
            marginRight={padding.small}
            margintop={"6px"}
          />
        </PaginationButton>
      )}
    </div>
  );
};

export default LongButton;
