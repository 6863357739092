import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import MainButton from "./ui/MainButton";

const StyledMainMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.padding.small};
  justify-content: center;
  position: fixed;
  top: ${(props) => `calc(${props.theme.padding.large} * 3)`};
  right: ${(props) => props.theme.padding.large};

  /* Animation for appearing and disappearing */
  ${(props) =>
    props.visible
      ? css`
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.3s ease, transform 0.3s ease;
        `
      : css`
          opacity: 0;
          transform: translateX(20px);
          transition: opacity 0.3s ease, transform 0.3s ease;
          pointer-events: none; /* Disable interaction during the transition */
        `}
`;

const MainMenu = ({
  onClick,
  textPort,
  toPort,
  textOff,
  toOff,
  textAbout,
  toAbout,
  visible,
}) => {
  const [renderPort, setRenderPort] = useState(false);
  const [renderOff, setRenderOff] = useState(false);
  const [renderAbout, setRenderAbout] = useState(false);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setRenderPort(true);
      }, 500); // Delay before showing the buttons

      return () => clearTimeout(timeout);
    } else {
      setRenderPort(false);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setRenderOff(true);
      }, 750); // Delay before showing the buttons

      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setRenderOff(false);
      }, 300); // Make sure the buttons disappear quickly (adjust the duration as needed)

      return () => clearTimeout(timeout);
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      const timeout = setTimeout(() => {
        setRenderAbout(true);
      }, 1000); // Delay before showing the buttons

      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        setRenderAbout(false);
      }, 200); // Make sure the buttons disappear quickly (adjust the duration as needed)

      return () => clearTimeout(timeout);
    }
  }, [visible]);

  return (
    <StyledMainMenu visible={visible}>
      <MainButton
        onClick={onClick}
        text={textPort}
        to={toPort}
        visible={renderPort}
        delay={"0"}
      />
      <MainButton
        onClick={onClick}
        text={textOff}
        to={toOff}
        visible={renderOff}
        delay={"0"}
      />
      <MainButton
        onClick={onClick}
        text={textAbout}
        to={toAbout}
        visible={renderAbout}
        delay={"0"}
      />
    </StyledMainMenu>
  );
};

export default MainMenu;
