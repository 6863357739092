import React, { useState } from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import styled, { keyframes } from "styled-components";
import { ChargeMediaTheme } from "../ChargemediaTheme";
import LoadingIndicator from "./LoadingIndicator";

const { colors, padding, dimens } = ChargeMediaTheme;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const GridItemTextContainer = styled.div`
  position: absolute;
  bottom: 0; /* Start at the bottom */
  width: 100%;
  height: 25%;
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  transform: translateY(100%); /* Start hidden below the container */
  opacity: 0;
  visibility: hidden;
`;

const GridItemContainer = styled.div`
  overflow: hidden;
  position: relative;
  top: ${padding.small};
  gap: ${padding.small};
  width: 100%;
  height: 100%;
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
  border: 0px solid #ccc;
  border-radius: ${dimens.boxRadius};
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  &:hover ${GridItemTextContainer} {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
`;
const GridItemWrapper = styled.div`
  animation: ${({ animationType, animationDelay }) =>
      animationType === "fadeIn" ? fadeInAnimation : fadeOutAnimation}
    0.5s ease-in-out;
  animation-fill-mode: both;
  animation-delay: ${({ animationType, animationDelay }) =>
    animationType === "fadeIn"
      ? animationDelay
      : animationDelay - 0.5}s; /* Adjust delay for fade-out */
`;

const GridItemText = styled.p`
  font-size: 24px;
`;
const GridItemImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const GridItemImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s ease;
  transform-origin: center center;
  cursor: pointer;

  &:hover {
    transform: scale(1.1); /* Scale the image by 10% */
  }
`;
const GridItem = ({
  children,
  imageUrl,
  title,
  animationType,
  animationDelay,
  onClick,
  to,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false);
    // Handle the error, e.g., display a placeholder image
  };

  return (
    <GridItemWrapper
      animationType={animationType}
      animationDelay={animationDelay}
      onClick={onClick}
    >
      <GridItemContainer>
        <GridItemImageContainer>
          {isLoading && <LoadingIndicator />} {/* Show loading indicator */}
          <GridItemImage
            src={imageUrl}
            alt=""
            onLoad={handleImageLoad}
            onError={handleImageError}
            style={{ display: isLoading ? "none" : "block" }} // Hide image while loading
          />
        </GridItemImageContainer>
        <GridItemTextContainer>
          <GridItemText>{title}</GridItemText>
        </GridItemTextContainer>
      </GridItemContainer>
    </GridItemWrapper>
  );
};

export default GridItem;
