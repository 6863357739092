import flags from "./components/props/flags";

const isLocal = flags.isLocal;

const API_URL = isLocal ? "http://localhost:1337" : "https://strapi.soldyn.de/";
const STRAPI_URL = isLocal
  ? "http://localhost:1337"
  : "https://strapi.soldyn.de";
const WEBSITE_URL = isLocal
  ? "http://localhost:3000"
  : "https://solutiondynamic.de";

export { API_URL, STRAPI_URL, WEBSITE_URL };
