import React, { useState } from "react";
import { ChargeMediaTheme } from "../ChargemediaTheme";

const { colors } = ChargeMediaTheme;
const { timing } = ChargeMediaTheme;
const ArrowIcon = ({ direction = "down", size = 20, color = colors.red }) => {
  const rotateDegrees = {
    up: 0,
    right: 90,
    down: 180,
    left: 270,
  };

  const arrowStyle = {
    width: size,
    height: size,
    transform: `rotate(${rotateDegrees[direction]}deg)`,
    transition: "transform 0.275s ease",
  };

  const arrowPathStyle = {
    fill: color,
    transition: "fill 0.275s ease",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="26"
      viewBox="0 0 14 26"
      style={arrowStyle}
    >
      <path d="M14 13L0.5 25.1244L0.5 0.875644L14 13Z" style={arrowPathStyle} />
    </svg>
  );
};

export default ArrowIcon;
