import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import SingleLineText from "./SingleLineText";

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
`;

const Button = styled(Link)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  width: 330px;
  height: 100px;
  border-radius: ${(props) => props.theme.dimens.boxRadius};
  border: none;
  color: ${(props) => props.theme.colors.darkGrey};
  transition: background-color ${(props) => props.theme.timings.btnFade}
    ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  animation: ${(props) => (props.visible ? slideIn : "")}
    ${(props) => props.theme.timings.btnFade} ease-in-out;
  animation-delay: ${(props) => (props.visible ? props.delay : "0s")};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  &:hover {
    background-color: ${(props) => props.theme.colors.darkGrey};
    color: ${(props) => props.theme.colors.lightGrey};
  }
`;

const MainButton = ({ onClick, text, to, visible, delay }) => {
  return (
    <>
      {visible && (
        <Button onClick={onClick} to={to} visible={visible} delay={delay}>
          <SingleLineText text={text} cursor={"pointer"} />
        </Button>
      )}
    </>
  );
};

export default MainButton;
