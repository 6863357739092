import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBLZy6_Weju94wAESXmMYhPykdnU7w_daM",
  authDomain: "sd-licenses.firebaseapp.com",
  projectId: "sd-licenses",
  storageBucket: "sd-licenses.appspot.com",
  messagingSenderId: "812563825075",
  appId: "1:812563825075:web:1f4a9876368748292f4bc6",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
