import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CenteredBox,
  BoxContainer,
  ContentBox,
  TitleBox,
  CurrentPageBox,
  BoxRow,
  BoxContentDiv,
  Inner,
  ContentLeft,
  ContentRight,
  Titletext,
  DescriptionText,
  ScrollContainer,
  ButtonContainer,
  TitletextPageLink,
  BoxContainerMobile,
} from "../../styled/PortfolioStyled";
import "./portfolStyles.css"; // Make sure to create the "WebsitesOffer.css" file in the same directory
import BackButton from "../../ui/BackButton";
import flags from "../../props/flags";
import PortfolioIcons from "../../ui/PortfolioIcons";
import { API_URL, STRAPI_URL } from "../../../config";
import LoadingIndicator from "../../ui/LoadingIndicator";
import { useTranslation } from "react-i18next";
import RichText from "../../ui/RichText";
import MiniGallery from "../../ui/MiniGallery";
import LongButton from "../../ui/LongButton";
import { ChargeMediaTheme } from "../../ChargemediaTheme";

const { colors } = ChargeMediaTheme;

const PrintOffer = ({ toggleMainMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [fadeOut, setFadeOut] = useState(false);
  const [isBackButtonActive, setIsBackBtnActive] = useState(true);

  const isModelLoaded = flags.isModelLoaded;
  const { t } = useTranslation();

  const [apiData, setData] = useState(null);
  const [apiDataPrevPage, setDataPrevPage] = useState(null);
  const [apiDataNextPage, setDataNextPage] = useState(null);
  //
  const numericId = parseInt(id, 10); // Convert id to a number using parseInt

  const nextPageId = numericId + 1;
  const prevPageId = numericId - 1;
  const apiUrl = `${API_URL}/api/print-angebotes/${id}?populate=*`;

  const apiUrlNextPage = `${API_URL}/api/print-angebotes/${nextPageId}?populate=*`;
  const apiUrlPrevPage = `${API_URL}/api/print-angebotes/${prevPageId}?populate=*`;
  //
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 865);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    toggleMainMenu(true);
    setIsBackBtnActive(true);
  }, []);

  const handleGoBack = () => {
    setFadeOut(true);
    toggleMainMenu(true);
    setTimeout(() => navigate(-1), 700); // Wait for 1 second (animation duration) before navigating back
  };

  const handleNextPage = () => {
    setFadeOut(true);
    toggleMainMenu(true);

    setTimeout(() => {
      navigate(`/offer/print/pj?id=${nextPageId}`);
      setFadeOut(false);
    }, 700);
  };

  const handlePrevPage = () => {
    setFadeOut(true);
    toggleMainMenu(true);

    setTimeout(() => {
      navigate(`/offer/print/pj?id=${prevPageId}`);
      setFadeOut(false);
    }, 700);
  };

  //Fetch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [apiData, prevPageData, nextPageData] = await Promise.all([
          fetch(apiUrl).then((response) => response.json()),
          fetch(apiUrlPrevPage).then((response) => response.json()),
          fetch(apiUrlNextPage).then((response) => response.json()),
        ]);
        console.log("Prev Page Data:", prevPageData); // Add this line
        console.log("Next Page Data:", nextPageData); // Add this line

        console.log(apiData.data.attributes.Name);
        setData(apiData.data.attributes);

        if (prevPageData && prevPageData.data && prevPageData.data.attributes) {
          console.log(prevPageData.data.attributes.Name);
          setDataPrevPage(prevPageData.data.attributes);
        } else {
          console.error("Error: Data is null or missing attributes");
          setDataPrevPage(null);
        }

        if (nextPageData && nextPageData.data && nextPageData.data.attributes) {
          console.log(nextPageData.data.attributes.Name);
          setDataNextPage(nextPageData.data.attributes);
        } else {
          console.error("Error: Data is null or missing attributes");
          setDataNextPage(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  if (!apiData) {
    return <LoadingIndicator />;
  }

  const nameNextPage = apiDataNextPage ? apiDataNextPage.Name : null;
  const namePrevPage = apiDataPrevPage ? apiDataPrevPage.Name : null;

  const Name = apiData.Name;
  const Beschreibungstitel = apiData.Beschreibungstitel;
  const WebsiteLink = apiData.Link;
  const thumbnail = STRAPI_URL + apiData.Thumbnail.data.attributes.url;
  // console.log("Gallery Data:", apiData.Gallery);
  const imageUrls = Array.isArray(apiData.Gallery.data)
    ? apiData.Gallery.data.map((image) => STRAPI_URL + image.attributes.url)
    : [];
  const Beschreibung = apiData.Beschreibung ? (
    <RichText content={apiData.Beschreibung} />
  ) : null;
  const icon = apiData.Icon?.data?.attributes?.url
    ? STRAPI_URL + apiData.Icon.data.attributes.url
    : null;
  //console.log("DATA");
  console.log("Web IMG URLs:", imageUrls);
  return (
    <div>
      <div
        className={`websites-container ${
          fadeOut ? "box-fade-out" : "box-fade-in"
        }`}
      >
        {isModelLoaded && apiData && !isMobile && (
          <CenteredBox>
            <BoxContainer>
              <Inner>
                {" "}
                <BoxRow>
                  {" "}
                  <TitleBox>
                    <Titletext>{Name}</Titletext>
                    {icon && <PortfolioIcons iconUrl={icon} />}
                  </TitleBox>
                  {!WebsiteLink ? null : (
                    <CurrentPageBox>
                      {" "}
                      <TitletextPageLink
                        href={WebsiteLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("visitWebsite")}
                      </TitletextPageLink>
                    </CurrentPageBox>
                  )}
                </BoxRow>
                <BoxContentDiv>
                  {" "}
                  <ContentBox>
                    {" "}
                    <ContentLeft>
                      <Titletext>{Beschreibungstitel}</Titletext>
                      <DescriptionText>{Beschreibung}</DescriptionText>
                    </ContentLeft>
                    <ContentRight>
                      <Titletext>{t("gallery")}</Titletext>
                      <MiniGallery images={imageUrls} />
                      <ButtonContainer style={{ marginTop: "18%" }}>
                        <ButtonContainer style={{ alignItems: "start" }}>
                          {namePrevPage ? (
                            <LongButton
                              direction={"down"}
                              text={namePrevPage}
                              visible={true}
                              color={colors.darkGrey}
                              hoverColor={colors.lightGrey}
                              onClick={handlePrevPage}
                            />
                          ) : null}
                        </ButtonContainer>
                        <ButtonContainer style={{ alignItems: "end" }}>
                          {nameNextPage ? (
                            <LongButton
                              direction={"up"}
                              text={nameNextPage}
                              visible={true}
                              isRightSided={true}
                              color={colors.darkGrey}
                              hoverColor={colors.lightGrey}
                              onClick={handleNextPage}
                            />
                          ) : null}
                        </ButtonContainer>
                      </ButtonContainer>
                    </ContentRight>
                  </ContentBox>
                </BoxContentDiv>
              </Inner>
            </BoxContainer>
          </CenteredBox>
        )}
        {isModelLoaded && apiData && isMobile && (
          <BoxContainerMobile>
            {" "}
            <TitleBox>
              <Titletext>{Name}</Titletext>
              {icon && <PortfolioIcons iconUrl={icon} />}
            </TitleBox>
            <Titletext>{Beschreibungstitel}</Titletext>
            <DescriptionText>{Beschreibung}</DescriptionText>
            <Titletext>{t("gallery")}</Titletext>
            <MiniGallery images={imageUrls} />
            <ButtonContainer style={{ alignItems: "start" }}>
              {namePrevPage ? (
                <LongButton
                  direction={"down"}
                  text={namePrevPage}
                  visible={true}
                  color={colors.darkGrey}
                  hoverColor={colors.lightGrey}
                  onClick={handlePrevPage}
                />
              ) : null}
            </ButtonContainer>
            <ButtonContainer style={{ alignItems: "end" }}>
              {nameNextPage ? (
                <LongButton
                  direction={"up"}
                  text={nameNextPage}
                  visible={true}
                  isRightSided={true}
                  color={colors.darkGrey}
                  hoverColor={colors.lightGrey}
                  onClick={handleNextPage}
                />
              ) : null}
            </ButtonContainer>
          </BoxContainerMobile>
        )}
      </div>
      <BackButton
        handleGoBack={handleGoBack}
        isactive={isBackButtonActive}
        setisactive={setIsBackBtnActive}
      />
    </div>
  );
};

export default PrintOffer;
